import { Button, Col, Form, Input, Select, Tag, DatePicker, Modal, message } from "antd";
import React, { useEffect, useState,useRef } from "react";
import ContentLayout from "../../layout/ContentLayout"
import DataTablePage from "../public/DataTablePage";
import moment from "moment";
import RefundOrderDetail from "./RefundOrderDetail";
import FormEditModal from "../public/FormEditModal";
import UploadImage from "../../components/UploadImage";
import PrintTicketModal from "./PrintTicketModal";
import MemberQuerySelect from "../public/MemberQuerySelect";
import { api } from "../../util/axios";

export default ({login}) => {

    const [reloadData, setReloadData] = useState(true);
    const [refundOrderNo, setRefundOrderNo] = useState(null);
    const [formData, setFormData] = useState(null);
    const [printOrder,setPrintOrder] = useState(null);
    const [loading,setLoading] = useState(false);
    const tableRef = useRef(null);

    return (
        <ContentLayout>
            <DataTablePage
                bordered={false}
                tableRef={tableRef}
                apiUrl="order/records"
                reloadData={reloadData}
                onLoadDataEnd={() => setReloadData(false)}
                queryValueTrans={values => {

                    const [startDate, endDate] = values.times ? values.times : [];
                    return {
                        ...values,
                        startTime: startDate ? startDate.toDate().getTime() : undefined,
                        endTime: endDate ? endDate.toDate().getTime() : undefined,
                        times: undefined
                    }
                }}
                tableProps={{
                    size:"small"
                }}

                topComponents={<>

                    <Col span={8} >
                        <Form.Item name="upOrderNo">
                            <Input placeholder="订单号" />
                        </Form.Item>

                    </Col>
                    <Col span={8}>
                        <Form.Item name="payType">
                            <Select placeholder="支付方式" allowClear >
                                <Select.Option value={""}>全部</Select.Option>
                                <Select.Option value={"WX_PAY"}>微信</Select.Option>
                                <Select.Option value={"ALI_PAY"}>支付宝</Select.Option>
                                <Select.Option value={"MEMBER_CARD"}>会员卡</Select.Option>
                                {/* <Select.Option value={"GIFT_CARD"}>礼品卡</Select.Option> */}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8} style={{ marginBottom: 10 }}>
                        <Form.Item name="orderStatus">
                            <Select placeholder="状态" allowClear >
                                <Select.Option value={""}>全部</Select.Option>
                                <Select.Option value={"0"}>未支付</Select.Option>
                                <Select.Option value={"1"}>成功</Select.Option>
                                <Select.Option value={"2"}>失败</Select.Option>
                                <Select.Option value={"3"}>超时</Select.Option>
                                <Select.Option value={"4"}>取消</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8} style={{ marginBottom: 10 }}>
                        <Form.Item name="shopUserName">
                            <Input placeholder="收银员" />
                        </Form.Item>
                    </Col>
                    <Col span={16} style={{marginBottom:10}} >
                        <Form.Item name="times">
                            <DatePicker.RangePicker
                                showTime
                                style={{ width: "100%" }}
                                placeholder={["开始时间", "结束时间"]}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8} style={{ marginBottom: 10 }}>
                        <Form.Item name="memberId">
                            <MemberQuerySelect/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item>
                            <Button type="primary" htmlType="submit">查询</Button>
                            <Button type="default" loading={loading} style={{marginLeft:10}} onClick={()=>{

                                tableRef.current.getParams().then(params=>{

                                    setLoading(true);

                                    api("order/records/downloand").downloand("收款记录.csv",{...params}).then(ret=>{
                                        message.success("下载成功");
                                    }).finally(()=>{
                                        setLoading(false);
                                    })

                                });

                            }}>导出</Button>
                            <Button type="default" htmlType="reset" style={{ marginLeft: 10 }} >重置</Button>
                        </Form.Item>
                    </Col>

                </>}

                columns={[
                    { title: "订单号", key: "upOrderNo", dataIndex: "orderNo" },
                    {
                        title: "金额", key: "amount",width:150, render: r => {
                            return (
                                <div>
                                    <div>应收：¥{r.amount.toFixed(2)}</div>
                                    <div>优惠：¥{(r.amount - r.amountTrue).toFixed(2)}</div>
                                    <div>实收：¥{r.amountTrue.toFixed(2)}</div>
                                    {r.amountRefund ? (
                                        <div>
                                            <a style={{ color: "red" }} onClick={() => setRefundOrderNo(r.upOrderNo)} >退款：¥{r.amountRefund.toFixed(2)}</a>
                                        </div>
                                    ) : null}
                                </div>
                            )
                        }
                    },
                    // { title: "付款", key: "amountReal", dataIndex: "amountReal" },
                    // { title: "优惠", key: "amountFree", dataIndex: "amountFree" },
                    // { title: "退款金额", key: "amountRefund", dataIndex: "amountRefund" },
                    {
                        title: "方式", key: "payType",width:100, render: r => {

                            const getMemberType = (m)=>{
                                 if(!m.memberId){
                                    return "会员卡";
                                 }
                                 return (
                                     <div>
                                         <div>会员卡</div>
                                         <div style={{fontSize:12}}>{`${m.memberType == "VIP"?"金融会员":"储值会员"}`}</div>
                                         <div style={{fontSize:12}}>{m.memberPhone}</div>
                                     </div>
                                 )
                            }

                            const payTypeMap = {
                                "MEMBER_CARD": getMemberType(r),
                                "WX_PAY": "微信",
                                "ALI_PAY": "支付宝",
                                "GIFT_CARD":"礼品卡"
                            };
                            return payTypeMap[`${r.payType}`]

                        }
                    },
                    {
                        title: "状态", key: "orderStatus", render: r => {

                            const statusMap = {
                                "0": <Tag color="processing">未支付</Tag>,
                                "1": <Tag color="green">成功</Tag>,
                                "2": <Tag color="error">失败</Tag>,
                                "3": <Tag>超时</Tag>,
                                "4": <Tag>取消</Tag>
                            }

                            return statusMap[`${r.payStatus}`]

                        }
                    },
                    { title: "收银员", key: "userName", dataIndex: "userName" },
                    { title: "收款时间", key: "createTime", render: r => moment(r.createTime).format("YYYY-MM-DD HH:mm") },
                    // {
                    //     title: "备注", key: "remark", width: 100, render: r => {
                    //         if (r.isRemark) {
                    //             return (
                    //                 <div style={{ maxWidth: 100 }}>
                    //                     {
                    //                         r.remarkImage ? <div style={{ marginBottom: 5 }}>
                    //                             <a onClick={() => {
                    //                                 Modal.info({
                    //                                     icon: null,
                    //                                     title: "图片预览",
                    //                                     content: <div style={{textAlign:"center"}}>
                    //                                         <img src={r.remarkImage} style={{ maxWidth: 300, maxHeight: 300, objectFit: "contain" }} />

                    //                                     </div>
                    //                                 })
                    //                             }}>
                    //                                 <img src={r.remarkImage} style={{ width: 30, height: 30, objectFit: "contain" }} />
                    //                             </a>

                    //                         </div> : null
                    //                     }
                    //                     <div style={{ fontSize: 12 }}>{r.remarkText}</div>

                    //                 </div>
                    //             )
                    //         }

                    //         return <a onClick={() => setFormData({ orderId: r.id })}>添加</a>
                    //     }
                    // },
                    {title:"操作",key:"opt",render:r=>{
                        return r.payStatus == 1?  <Button size="small" onClick={()=>setPrintOrder({...r})}>打印</Button>:null;
                    }}
                ]}

            />
            <RefundOrderDetail
                payOrderNo={refundOrderNo}
                onClose={() => setRefundOrderNo(null)}
            />

            <FormEditModal
                apiUrl="order/record/remark"
                formData={formData}
                modalProps={{
                    title: "添加备注"
                }}
                onCancel={() => setFormData(null)}
                onOk={() => {
                    setFormData(null);
                    setReloadData(true);
                }}
                formValueTrans={values => {
                    return {
                        ...values,
                        orderId: formData.orderId
                    }
                }}
                formItems={<>

                    <Form.Item label="图片" name="remarkImage">
                        <UploadImage />
                    </Form.Item>
                    <Form.Item label="备注" name="remarkText" rules={[{ required: true }]}>
                        <Input.TextArea maxLength={100} placeholder="100字以内" />
                    </Form.Item>

                </>}

            />

            <PrintTicketModal order={printOrder} login={login} onCancel={()=>setPrintOrder(null)}/>
        </ContentLayout>
    )
}