import { Modal, Form, Input, InputNumber, message } from "antd";
import React, { useRef, useState } from "react";
import EditModal from "../../components/EditModal";
import { api } from "../../util/axios";

const payTypeMap = {
    "MEMBER_CARD": "会员卡",
    "WX_PAY": "微信",
    "ALI_PAY": "支付宝"
};

export default ({ onCancel, onOk, order }) => {

    const layout = {
        labelCol: { span: 5 },
        wrapperCol: { span: 16 }
    }

    const form = useRef(null);

    const [loading, setLoading] = useState(false);

    const refundOrder = (data) => {

        setLoading(true);
        api("order/refund").post({
            ...data,
            orderNo:order.orderNo
        }).then(ret=>{
            if(ret.code == 200){
                message.success(ret.msg);
                onOk();
            }
        }).finally(()=>{
            setLoading(false);
        })


    }

    return (
        <EditModal
            title="退款"
            visible={order ? true : false}
            // footer={null}
            style={{
                minWidth: 300
            }}
            onCancel={onCancel}
            okButtonProps={{loading:loading}}
            onOk={() => {
                form.current.validateFields().then(values => {

                    refundOrder({
                        ...values
                    })

                }).catch(() => {

                })
            }}
        >
            {
                order ? (
                    <Form {...layout} ref={ref => form.current = ref}>
                        <Form.Item label="系统单号" style={{marginBottom:10}}>
                            <span>{order.orderNo}</span>
                        </Form.Item>
                        <Form.Item label="上游单号"  style={{marginBottom:10}}>
                            <span>{order.upOrderNo}</span>
                        </Form.Item>
                        <Form.Item label="支付方式"  style={{marginBottom:10}}>
                            <span>{payTypeMap[`${order.payType}`]}</span>
                        </Form.Item>
                        <Form.Item label="订单金额"  style={{marginBottom:10}}>
                            <span>{order.amount}</span>
                        </Form.Item>
                        <Form.Item label="实际付款" style={{marginBottom:10}}>
                            <span>{order.amountTrue}</span>
                        </Form.Item>
                        <Form.Item label="已退金额"   style={{marginBottom:10}}>
                            <span>{order.amountRefund}</span>
                        </Form.Item>
                        <Form.Item label="退款金额" name="amount" rules={[{required:true}]}>
                           <InputNumber style={{width:"100%"}} max={order.amountTrue - order.amountRefund} min={0.01}/>
                        </Form.Item>
                        <Form.Item label="退款原因" name="reason" rules={[{required:true}]}>
                            <Input.TextArea rows={3}/>
                        </Form.Item>
                    </Form>
                ) : null
            }

        </EditModal>
    )
}
