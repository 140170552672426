import { Form, InputNumber, Input, message, Button, Modal, Spin, Result, Radio } from "antd";
import React, { useRef, useState } from "react";
import MemberQuerySelect from "../public/MemberQuerySelect";
import QrcodeAuthPay from "../payment/QrcodeAuthPay";
import { LoadingOutlined } from '@ant-design/icons';
import { api } from "../../util/axios";
import RechargeAmountSelect from "../public/RechargeAmountSelect";
import UploadImageList from "../../components/UploadImageList";


const PayQrcodeFormItem = (value, onChange) => {

    return (
        <div>

            <div style={{ marginBottom: 10 }}>
                <Input placeholder="付款码" onPressEnter={(e) => {
                    const value = e.target.value;
                    if (!value) {
                        message.error("付款码不能为空");
                        return;
                    }
                    // onOk(value);
                }} />
            </div>
            <div style={{ textAlign: "center", marginBottom: 10 }}>
                <img src={"/img/bar-pay.jpg"} style={{ width: "100%" }} />
            </div>
            <div style={{ marginBottom: 0 }}>
                <span style={{ fontSize: 14, color: "#888" }}>打开微信/支付宝 显示付款码</span>
            </div>
        </div>
    )
}

const PayLoadingModal = ({ visible }) => {

    return (
        <Modal
            visible={visible}
            closable={false}
            footer={null}
            maskClosable={false}
            centered={true}
            width={320}
            title="支付中..."
        >

            <div style={{ display: "flex", alignItems: "center", justifyItems: "center", justifyContent: "center", marginBottom: 10 }}>
                <Spin indicator={<LoadingOutlined style={{ fontSize: 32 }} />}></Spin>
            </div>
            <div style={{ textAlign: "center" }}>
                <span>正在支付中...</span>
                {/* <a>120S</a> */}
            </div>

        </Modal>
    )

}

const PayResultModal = ({ visible, data, onOk }) => {

    const getStatus = () => {

        if (!data) {
            return null;
        }
        if (data.payStatus == "SUCCESS") {
            return "success";
        }
        if (data.payStatus == "FAIL") {
            return "error"
        }
        return null;

    }

    console.log("status", getStatus());

    return (
        <Modal

            title="结果"
            width={400}
            onOk={onOk}
            onCancel={onOk}
            visible={data ? true : false}
            footer={null}

        >

            {
                data ? (
                    <div>
                        <Result
                            status={getStatus()}
                            title={data.message}
                            style={{ marginBottom: 10, padding: 10 }}

                        />
                        <div style={{ width: 350, margin: "0 auto", marginBottom: 25, backgroundColor: "#ddd", borderRadius: 5, padding: 10 }}>
                            <div>
                                <span >订单编号：</span><span className="res-item-right">{data.orderNo}</span>
                            </div>
                            <div >
                                <span>充值金额：</span><span className="res-item-right">{data.amount.toFixed(2)}</span>
                            </div>
                            <div >
                                <span >会员姓名：</span><span className="res-item-right">{data.memberName}</span>
                            </div>
                            <div>
                                <span>会员手机：</span><span className="res-item-right">{data.memberPhone}</span>
                            </div>

                        </div>
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}>

                            <Button size="large" type="primary" style={{ width: 350 }} autoFocus={true} onClick={() => {
                                onOk(data.payStatus);
                            }} >确定</Button>
                        </div>

                    </div>
                ) : null
            }

        </Modal>
    )

}

export default ({ jumpTabTo }) => {

    const layout = {
        labelCol: { span: 5 },
        wrapperCol: { span: 12 }
    }

    const form = useRef(null);
    const [payModal, setPayModal] = useState(false);
    const [payLoading, setPayLoading] = useState(false);
    // const [payResultVisable, setPayResultVisable] = useState(true);
    const [payResultData, setPayResultData] = useState(null);

    const rechargePay = (values) => {


        setPayLoading(true);

        api("member/recharge")
            .post({
                ...values
            }).then(ret => {

                if (ret.code != 200) {

                    // message.error(ret.msg);

                    return;
                }

                // console.log(ret.data);
                const data = {
                    ...ret.data
                };

                setPayResultData(data);




            })
            .catch(() => {
                message.error("网络错误");
                // setPayLoading(false);
            })
            .finally(() => {
                setPayLoading(false);
            })


    }




    return (
        <div>

            <Form
                style={{ width: 650, marginTop: 30 }}
                {...layout}
                onFinish={values => {
                    // console.log(values);
                    if(values.rechargeType == "qrcode"){
                        setPayModal(true);
                        
                    }
                    else if(values.rechargeType == "other"){
                        Modal.confirm({
                            title:"提示",
                            content:"确认充值？",
                            onOk:()=>{
                                rechargePay({
                                    ...values
                                });
                            }
                        })
                     
                    }

                }}
                initialValues={{ rechargeType: "qrcode" }}
                ref={ref => form.current = ref}
            >
                <Form.Item label="会员" name="memberId" rules={[{ required: true }]}>
                    <MemberQuerySelect />
                </Form.Item>
                <Form.Item label="充值金额" name="configId" rules={[{ required: true }]}>
                    {/* <InputNumber precision={2} style={{ width: "100%" }} max={50000} min={0.01} placeholder="1000-50000" /> */}
                    <RechargeAmountSelect />
                </Form.Item>
                <Form.Item label="付款方式" name="rechargeType" rules={[{ required: true }]}>
                    <Radio.Group>
                        <Radio.Button value={"qrcode"}>扫码付款</Radio.Button>
                        <Radio.Button value={"other"}>其他方式</Radio.Button>
                    </Radio.Group>
                </Form.Item>
                <Form.Item noStyle shouldUpdate={(p, c) => p.rechargeType != c.rechargeType}>
                    {
                        ({ getFieldValue }) => {
                            const rechargeType = getFieldValue("rechargeType");
                            // console.log("rechargeType=>",rechargeType);
                            if (rechargeType == "other") {
                                return (
                                    <>
                                        <Form.Item label="收款凭证" name="image" rules={[{ required: true }]}>
                                            <UploadImageList />
                                        </Form.Item>
                                    </>
                                )
                            }

                            return null;
                        }
                    }
                </Form.Item>
                {/* <Form.Item label="付款码" name="authCode" rules={[{ required: true }]}>
                    <PayQrcodeFormItem />
                </Form.Item> */}
                <Form.Item label="邀请码" name="inviteCode" >
                    <InputNumber maxLength={4} />
                </Form.Item>
                <Form.Item wrapperCol={{ offset: 5, span: 8 }} >
                    <Button type="primary" htmlType="submit">确认充值</Button>
                </Form.Item>

            </Form>
            <QrcodeAuthPay
                visible={payModal}
                onCancel={() => setPayModal(false)}
                onOk={(code) => {

                    setPayModal(false);
                    // setPayLoading(true);
                    const formValues = form.current.getFieldsValue();
                    rechargePay({
                        authCode: code,
                        ...formValues
                    });

                }}
            />

            <PayLoadingModal visible={payLoading} />
            <PayResultModal
                // visible={payResultVisable}
                data={payResultData}
                onOk={(payStatus) => {
                    setPayResultData(null);
                    if (payStatus == "SUCCESS") {
                        jumpTabTo("records");
                    }
                }}
            />
        </div>
    )
}