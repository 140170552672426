import { Button, Col, Form, Input, Select, Tag, DatePicker, Modal } from "antd";
import React, { useEffect, useState } from "react";
import ContentLayout from "../../layout/ContentLayout"
import DataTablePage from "../public/DataTablePage";
import moment from "moment";

export default ({ payOrderNo, onClose }) => {


    useEffect(() => { }, [payOrderNo]);


    return (
        <Modal
            title="退款详情"
            visible={payOrderNo ? true : false}
            destroyOnClose={true}
            onOk={onClose}
            width={960}
            onCancel={onClose}
        >
            {
                payOrderNo ? (
                    <DataTablePage
                        apiUrl="order/refund/record"
                        queryParams={{
                            pageNo: 1,
                            pageSize: 100,
                            payOrderNo: payOrderNo
                        }}
                        columns={[
                            { title: "订单号", key: "orderNo", dataIndex: "payOrderNo" },
                            { title: "退款金额", key: "amount", dataIndex: "amount" },
                            {
                                title: "状态", key: "orderStatus", render: r => {
                                    return r.orderStatus ? <Tag color="success">成功</Tag> : <Tag>失败</Tag>

                                }
                            },

                            { title: "操作账号", key: "userName", dataIndex: "userName" },
                            {title:"备注",key:"reason",dataIndex:"reason"},
                            { title: "退款时间", key: "createTime", render: r => moment(r.createTime).format("YYYY-MM-DD HH:mm") }

                        ]}

                    />
                ) : null
            }


        </Modal>
    )
}