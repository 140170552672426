import { Form, Input, Row, Col, Select, Button } from "antd";
import React, { useRef, useState } from "react";
import EditModal from "../../components/EditModal";
import { api } from "../../util/axios";

const QueryForm = ({onSelect}) => {

    const [searchValue,setSearchValue] = useState("");

    const searchTarget = useRef(null);
    const [members,setMembers] = useState([]);
    const [member,setMember] = useState(null); 

    const loadData = (query)=>{
        if(!query){
            setMembers([]);
            return;
        }
        api("api/member/query").get({query:query}).then(ret=>{
            if(ret.code == 200){
                setMembers(ret.data);
            }
        })
    }

    return (
        <Form>
            <Form.Item  style={{marginBottom:10}}>
                <Select
                    placeholder={searchValue?"":"卡号/手机号"}
                    searchValue={searchValue}
                    showSearch={true}
                    showAction={"focus"}
                    value={member}
                    autoFocus={true}
                    onSearch={value=>{
                        setSearchValue(value);
                        // console.log("search-value:",value);
                        loadData(value);
                    }}
                    allowClear={true}
                    ref={ref=>searchTarget.current = ref}
                    onChange={(value)=>{
                        // console.log("s:",value);
                        setMember(value);
                    }}
                >
                    {
                        members.map(m=>{
                            return (
                                <Select.Option key={m.id} value={JSON.stringify({...m})}>
                                    {`(${m.realName}) ${m.phone}`}
                                </Select.Option>
                            )
                        })
                    }
                </Select>
            </Form.Item>
            <Form.Item style={{marginBottom:0}}>
                <Row style={{ backgroundColor: "#f1f1f1", padding: 1 }}>
                    {
                        [1, 2, 3, 4, 5, 6, 7, 8, 9, 0, "清空", "搜索"].map((num, index) => {
                            return (
                                <Col 
                                    key={index} 
                                    span={8} style={{ padding: 1 }}
                                    onClick={()=>{
                                        
                                        //删除
                                        if("清空"  == num ){
                                            setSearchValue("");
                                            setMember(null);
                                            return;
                                        }
                                        if("搜索" == num){
                                            loadData(searchValue);
                                            searchTarget.current.focus();
                                            return;
                                        }

                                        if(member){
                                            setMember(null);
                                        }
                                       
                                        const value = `${searchValue}${num}`;
                                        setSearchValue(value);
                                        
                                        
                                    }}
                                >
                                    <div className="amount-num-item" style={{
                                        backgroundColor: num == "搜索" ? "#fff" : "",
                                        padding:10
                                    }}
                                    >
                                        {num}
                                    </div>
                                </Col>
                            )
                        })
                    }
                    <Col span={24} style={{padding:1}}>
                        <div className="amount-num-item" style={{backgroundColor:"#E7C77B",padding:10}} onClick={()=>{
                            if(member){
                                onSelect(JSON.parse(member));
                            }
                        }}>
                            确 定
                        </div>
                    </Col>
                </Row>
            </Form.Item>
            
        </Form>
    )
}

export default (props) => {



    return (
        <EditModal

         
            {...props}
            title="选择会员"
            width={300}
            footer={null}
            style={{
                minWidth: 300
            }}
            // bodyStyle={{
            //     padding:10
            // }}
            // headerStyle={{
            //     padding:10
            // }}
        >
            <QueryForm onSelect={member=>{
                props.onOk(member);
            }} />
        </EditModal>
    )
}