import React, { useEffect, useRef, useState } from 'react';
import { Form, Input, message, Radio, Select } from 'antd';
import EditModal from '../../components/EditModal';
import { api } from '../../util/axios';






export default ({ formData = null, apiUrl, formItems,formValueTrans,modalProps={
    
},onOk,onCancel,onClose }) => {


    let form = useRef(null);
    const [loading,setLoading] = useState(false); 
    const layout = {
        labelCol: { span: 7 },
        wrapperCol: { span: 12 }
    }
    useEffect(() => {
    }, [formData ? formData.id : null]);

    const onSave = (values)=>{

        setLoading(true);
        api(apiUrl).
        save(formValueTrans?formValueTrans(values):values).
        then((ret)=>{
            if(ret.code == 200){
                message.success(ret.msg);
                if(onOk){
                    onOk();
                }
                if(onClose){
                    onClose();
                }
            }
        }).finally(()=>{
            setLoading(false);
        })

    }

    return (
        <EditModal


          
            title={formData ? (formData.id ? "修改" : "添加") : ""}
            visible={formData ? true : false}
            confirmLoading={loading}
            onCancel={()=>{
                if(onCancel){
                    onCancel();
                }
                if(onClose){
                    onClose();
                }
            }}
            onOk={() => {
                if(!apiUrl){
                    if(onOk){
                        onOk();
                    }
                    if(onClose){
                        onClose();
                    }
                    return;
                }
                form.current.validateFields().then(values => {
                    onSave(values);
                }).catch((e) => {

                    console.log(e);
                    const name = e.errorFields[0]["name"][0];
                    console.log("name=>",name);
                    form.current.scrollToField(name);
                })
            }}
            {...modalProps}
            >
            <Form ref={ref => form.current = ref} {...layout} initialValues={formData}>
                {
                    formData && formData.id ? (
                        <Form.Item name="id" style={{ display: "none" }} >
                            <Input type="hidden" />
                        </Form.Item>
                    ) : null
                }
                {formItems}

            </Form>

        </EditModal>
    )
}