import { Form, Input, message, Modal} from "antd";
import React, { useRef } from "react";

export default ({ visible, onCancel, onOk }) => {

    const form = useRef(null);

    return (
        <Modal
            visible={visible}
            title="礼品卡支付"
            width={320}
            onCancel={onCancel}
            destroyOnClose={true}
            maskClosable={false}
            onOk={() => {


                form.current.validateFields().then(values => {
                    if (!values.authCode) {
                        message.error("付款码不能为空");
                        return;
                    }
                    onOk(values.authCode);
                }).catch((e) => {


                })
            }}
        >

            <div style={{ textAlign: "center", marginBottom: 15 }}>
                <img src={"/img/bar-pay.jpg"} style={{ width: "100%" }} />
            </div>

            <div style={{ marginBottom: 10 }}>

                <Form ref={ref => form.current = ref}>
                    <Form.Item name="authCode" style={{ marginBottom: 10 }}>
                        <Input placeholder="付款码" autoFocus={true} onPressEnter={(e) => {
                            const value = e.target.value;
                            if (!value) {
                                message.error("付款码不能为空");
                                return;
                            }
                            onOk(value);
                        }} />
                    </Form.Item>
                </Form>
            </div>
            <div>
                <span style={{ fontSize: 12, color: "#888" }}>打开安建得行小程序 显示礼品卡付款码</span>
            </div>



        </Modal>
    )

}