import React, { Component, useEffect, useState } from "react";
import { Layout, Menu, Modal, Dropdown, Breadcrumb, Button, Input, Form,Result } from 'antd';
import { Link, Redirect, Switch, Route } from 'react-router-dom';
import routes from "../config/admin-routes";
import {
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    UserOutlined,
    LogoutOutlined,
    DownOutlined,
    GoogleOutlined,
    LockOutlined,
    PayCircleOutlined

} from '@ant-design/icons';
import "./ba-layout.less";
import { api } from "../util/axios";
import AuthLoading from "../components/AuthLoading";






const { Header, Sider, Content } = Layout;


export default ({ history }) => {

    const [selectIndex, setSelectIndex] = useState(0);

    const [login, setLogin] = useState(null);

    const [unLockLoading,setUnLockLoading] = useState(false);

    const [loadingError,setLoadingError] = useState(false);

    const loadLoginStatus = () => {
        api("login/status").get().then(ret => {
            if (ret.code == 200) {
                setLogin(ret.data);
            }
        }).catch(ret=>{
            setLoadingError(true);
        })
    }

    useEffect(() => {

        loadLoginStatus();

    }, []);

    if(loadingError){
        return (
            <div style={{ display: "flex", flex: 1, alignItems: "center", justifyContent: "center", width: "100%", height: "100%" }}>
                <Result
                    status="error"
                    title="加载失败，请刷新"
                    extra={[
                        <Button type="primary" onClick={()=>window.location.reload(true)}>点击刷新</Button>
                    ]}
                />
            </div>
        )
    }

    if (!login) {
        return (
            <div style={{ display: "flex", flex: 1, alignItems: "center", justifyContent: "center", width: "100%", height: "100%" }}>
                <AuthLoading />
            </div>
        )
    }

    //屏幕锁定
    if (login.isLock) {

        return (
            <div style={{
                display: "flex",
                flex: 1,
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                height: "100%",
                backgroundColor:"rgba(0,0,0,0.7)"
            }}
            >
                <Form style={{backgroundColor:"#fff",border:"1px solid #fefefe",padding:20,borderRadius:10,width:400}} onFinish={values=>{

                    setUnLockLoading(true);
                    api("login/unlock").postForm(values).then(ret=>{
                        if(ret.code == 200){
                            loadLoginStatus();
                        }
                    }).finally(()=>{
                        setUnLockLoading(false);
                    })
                }} >
                    <Form.Item>
                        <h1 style={{textAlign:"center"}}>屏幕已锁定</h1>
                    </Form.Item>
                    <Form.Item name="password" rules={[{required:true,message:"登录密码不能为空"}]}>
                        <Input type="password" placeholder="登录密码" />
                    </Form.Item>
                    <Form.Item>
                        <Button loading={unLockLoading} htmlType="submit" type="primary" block>解锁</Button>
                    </Form.Item>
                    <Form.Item>
                        <div style={{color:"#666"}}>
                            <span>当前登录账号：{login.userName}</span>
                        </div>
                    </Form.Item>
                </Form>
            </div>
        )
    }

    return (
        <div id="ba-layout">
            <Layout style={{ width: "100%", height: "100%" }}>
                <Header style={{ backgroundColor: "#fff", position: "fixed", top: 0, width: "100%", zIndex: 1, borderBottom: "1px solid #f0f0f0", }} >
                    {/* <div style={{ display: "flex", alignItems: "center", height: 64, width: "50%" }}>
                        <div style={{ width: 100, height: 64, backgroundColor: "#001529", textAlign: "center" }}>
                            <img src={`${login.shopImgUrl}`} style={{ width: 40,height:40,borderRadius:20 }} />
                        </div>
                    </div> */}

                    <div style={{ width: "50%" }}>
                        <div style={{ paddingLeft: 105, display: "flex", alignItems: "center" }}>
                            {/* <span style={{fontSize:20}}>
                                {routes[selectIndex].icon}
                            </span> */}


                            <span style={{ marginLeft: 10, color: "rgba(0, 0, 0, 0.45)", fontSize: 16 }}>{routes[selectIndex].title}</span>
                        </div>
                    </div>

                    <div style={{
                        width: "50%",
                        paddingRight: "25px",
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center"

                    }}>

                        <Dropdown overlay={
                            <Menu>
                                <Menu.Item>
                                    <a onClick={() => {
                                        const modal = Modal.confirm({
                                            title: "提示",
                                            content: "是否锁定屏幕?",

                                            onOk: (close) => {
                                                modal.update({
                                                    okButtonProps: {
                                                        loading: true
                                                    }
                                                });
                                                api("login/lock").post().then(ret => {
                                                    if (ret.code == 200) {
                                                        close();
                                                        loadLoginStatus();
                                                    }
                                                }).finally(() => {
                                                    modal.update({
                                                        okButtonProps: { loading: false }
                                                    })
                                                })
                                            }
                                        })
                                    }}>
                                        <LockOutlined />
                                        <span style={{ marginLeft: 5 }}>{"屏幕锁定"}</span>
                                    </a>
                                </Menu.Item>
                                <Menu.Item>
                                    <a onClick={() => {
                                        Modal.confirm({
                                            title: "提示",
                                            content: "是否退出登陆",
                                            onOk: () => {
                                                api("logout").post().then(ret => {
                                                    history.push("/login");
                                                });
                                            }
                                        })
                                    }}>
                                        <LogoutOutlined />
                                        <span style={{ marginLeft: 5 }}>{"退出登陆"}</span>
                                    </a>
                                </Menu.Item>
                            </Menu>
                        } placement="bottomRight">
                            <div style={{ display: "flex", justifyItems: "center", alignItems: "center" }} >

                                <img src={login.shopImgUrl} style={{ width: 30, height: 30, borderRadius: 15 }} />
                                <span style={{ marginLeft: 5, marginRight: 5 }}>
                                    {`${login.shopName} - ${login.userName}`}
                                </span>


                                <DownOutlined />
                            </div>

                        </Dropdown>
                    </div>
                </Header>
                <Layout>

                    <Sider width={100} style={{ zIndex: 2 }} >
                        <div style={{ height: 10 }}></div>
                        {
                            routes.map((item, index) => {
                                return (
                                    <div style={{ paddingTop: 5 }} key={index} className={`menu-item ${index == selectIndex ? "menu-item-active" : ""}`}>
                                        <a href={`/#${item.path}`} className="menu-item-a" onClick={() => {
                                            setSelectIndex(index);
                                        }}>
                                            {item.icon}
                                            <span style={{ fontSize: 16 }}>{item.title}</span>
                                        </a>
                                    </div>
                                )
                            })
                        }


                    </Sider>
                    <Content className="site-layout" id="layoutBody" style={{ paddingTop: 64, overflowY: "auto", backgroundColor: "#fff" }}>
                        {
                            routes.map((item, index) => {
                                return <Route key={index} path={item.path} render={props => {
                                    const Page = item.component;
                                    if (Page) {
                                        return <Page {...props} login={login} />
                                    }
                                    return null;
                                }} />
                            })
                        }
                    </Content>
                </Layout>
            </Layout>
        </div>
    )
}
