import { Button, Col, DatePicker, Form, Tag } from "antd";
import React, { useState } from "react";
import DataTablePage from "../public/DataTablePage";
import MemberQuerySelect from "../public/MemberQuerySelect";
import moment from "moment";


export default ({}) => {


   

    return (
        <>
        <DataTablePage
            apiUrl="member/query"
            queryParams={{memberId:0}}
            topComponents={<>
                <Col span={8}>
                    <Form.Item name="memberId">
                        <MemberQuerySelect />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">查询</Button>
                    </Form.Item>
                </Col>

            </>}
            tableProps={{
                bordered:false
            }}
            pagination={false}
            columns={[
                { title: "卡号",key:"cardNo",dataIndex:"cardNo" },
                { title: "姓名",key:"realName",dataIndex:"realName" },
                { title: "手机", key: "phone", dataIndex: "phone" },
                { title: "余额", key: "balance", render:r=><a>{r.balance}</a> },   
                {title:"会员类型",key:"typeNme",dataIndex:"typeName"} 
                // { title: "赠送金额", key: "giveAmount", dataIndex: "giveAmount" },     
                // {title:"状态",key:"status",render:r=><Tag color="green">成功</Tag>},
                // {title:"收银员",key:"shopUserName",dataIndex:"shopUserName"},
                // {title:"充值时间",key:"createTime",render:r=>moment(r.createTime).format("YYYY-MM-DD HH:mm:ss")},
                
            ]}

        />
           
        </>
    )
}