import Modal from "antd/lib/modal/Modal";
import React, { useState } from "react";
import moment from "moment";
import { withConfirm } from "antd/lib/modal/confirm";



const payTypeMap = {
    "MEMBER_CARD": "会员卡",
    "WX_PAY": "微信支付",
    "ALI_PAY": "支付宝"
};

export default ({ order, login, onCancel }) => {


    const getPayType = (o) => {
        if (!o) {
            return null;
        }

        if (o.payType == "MEMBER_CARD") {

            return `会员卡- (${o.memberTypeName} ${o.memberPhone})`

        }
        return payTypeMap[order.payType];
    }

    return (
        <Modal
            visible={order ? true : false}
            title="小票打印"
            cancelText="取消"
            okText="确定"
            centered={true}
            onOk={() => {

                // window.print();

                const win = window.document.getElementById("print-iframe").contentWindow;

                win.document.body.innerHTML = window.document.getElementById("print-content").innerHTML;


                win.print();

                //    alert(s);

                onCancel();

            }}
            onCancel={onCancel}
        >
            {
                order ? (
                    <div id="print-content" style={{ maxWidth: 640, fontSize: 12 }}>
                        {/* <div style={{textAlign:"center",marginBottom:5}}>
                           
                        </div> */}
                        <div style={{ textAlign: "center", marginBottom: 10, width: "100%" }}>
                            <div style={{ width: "100%", display: "flex", alignItems: "center", justifyItems: "center" }}><img src={login.shopImgUrl} style={{ width: 30, height: 30, borderRadius: 15 }} /> <span style={{ marginLeft: 10, fontSize: 16, fontWeight: "bold" }}>{login.shopName}</span></div>
                        </div>
                        <div style={{ width: "100%", border: 0, borderBottom: "1px dashed #000", marginBottom: 10 }}></div>
                        <div style={{ marginBottom: 3 }}>订单编号:  <span>{order.orderNo}</span></div>
                        <div style={{ marginBottom: 3 }}>应收金额: <span>{order.amount.toFixed(2)}</span></div>
                        <div style={{ marginBottom: 3 }}>优惠金额: <span>{(order.amount - order.amountTrue).toFixed(2)} </span></div>
                        <div style={{ marginBottom: 3 }}>实收金额: <span>{order.amountTrue.toFixed(2)} </span></div>
                        {order.amountRefund ? (<div style={{ marginBottom: 3 }}>退款金额: <span>-{order.amountRefund.toFixed(2)} </span></div>) : null}
                        <div style={{ marginBottom: 3 }}>支付方式: <span>{getPayType(order)}</span></div>
                        <div style={{ marginBottom: 3 }}>支付时间: <span>{moment(order.payTime).format("YYYY-MM-DD HH:mm")}</span></div>
                        <div style={{ marginBottom: 10 }}>收银账号: <span>{order.userName}</span></div>
                        <div style={{ width: "100%", border: 0, borderBottom: "1px dashed #000", marginBottom: 10 }}></div>
                        <div style={{ marginBottom: 3 }}>打印日期: <span>{moment(new Date()).format("YYYY-MM-DD HH:mm")}</span></div>
                        <div style={{ marginBottom: 50, height: 50 }}>客户签名：</div>
                    </div>
                ) : null
            }

            <iframe id="print-iframe" style={{ maxWidth: 640, display: "none" }}></iframe>


        </Modal>
    )


}