
import React, {Component } from 'react';
import { Form, Upload, message } from "antd";
import config from '../config';
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";

export default class UploadImage extends Component {


    state = {
        loading:false
    }


    render() {
        const {loading} = this.state;
        const uploadButton = (
            <div>
                {loading ? <LoadingOutlined /> : <PlusOutlined />}
                <div className="ant-upload-text">上传</div>
            </div>
        );
        return (
            <Upload
                name="file"
                accept="image/*"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                action={`${config.baseApiUrl}/upload/image`}
                withCredentials={true}
                headers={{
                    "AJDX-SESSION-ID":localStorage.getItem("AJDX-SESSION-ID")
                }}
                beforeUpload={()=>{
                    this.setState({loading:true})
                    return true
                }}
                onChange={(info)=>{
                    if(info.file.status === 'done'){
                        this.setState({loading:false})
                        const {code,data,msg} = info.file.response;
                        if(code == 200){
                            this.props.onChange(data);
                            
                        }else{
                            message.error(msg);
                        }
                       
                    }
                }}
            >
                {this.props.value ? <img src={`${this.props.value}`} alt="avatar" style={{ width: 100,height:100, objectFit:"contain"}} /> : uploadButton}
            </Upload>)
    }
}