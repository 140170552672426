import React, { Component } from "react";
import { Link, Redirect } from 'react-router-dom';
import asyncComponent from "../components/AsyncComponent";
import {
    PayCircleOutlined,
    SearchOutlined,
    ExportOutlined,
    ShoppingOutlined,
    UnorderedListOutlined,
    LineChartOutlined,
    PropertySafetyOutlined,
    UserOutlined
} from '@ant-design/icons';
import Payment from "../pages/payment";
import Refund from "../pages/refund";
import Records from "../pages/record";
import hx from "../pages/hx";
import Stats from "../pages/stats"
import Recharge from "../pages/recharge";
import Member from "../pages/member";


const routes = [
    
    {
        title:"收款",
        icon:<PayCircleOutlined/>,
        path:"/payment",
        component:Payment,
        menu:true
    },
    {
        title:"充值",
        icon:<PropertySafetyOutlined />,
        path:"/recharge",
        component:Recharge,
        menu:true
    },
    {
        title:"退款",
        icon:<ExportOutlined />,
        path:"/refund",
        component:Refund,
        menu:true
    },
    {
        title:"核销",
        icon:<ShoppingOutlined />,
        path:"/hx",
        menu:true,
        component:hx
    },
    {
        title:"会员",
        icon:<UserOutlined />,
        path:"/member",
        menu:true,
        component:Member
    },
    {
        title:"记录",
        icon:<UnorderedListOutlined />,
        path:"/records",
        menu:true,
        component:Records
    },
    {
        title:"统计",
        icon:<LineChartOutlined />,
        path:"/stats",
        menu:true,
        component:Stats
    }
  

];



export default routes ;







