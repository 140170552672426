import { Button, Card, Col, Drawer, Form, Input, List, Row, Space, Popconfirm, message, InputNumber } from "antd";
import React, { useEffect, useRef, useState } from "react";
import {
    DeleteOutlined,
    ScanOutlined,
    CreditCardOutlined,
    UserOutlined,
    ProfileOutlined,
    DoubleRightOutlined,
    QrcodeOutlined,
    GiftFilled
} from '@ant-design/icons';
import EditModal from "../../components/EditModal";
import MemberSearchModal from "../public/MemberSearchModal";
import "./index.less"
import MPasswordModal from "./MPasswordModal";
import CouponSearchModal from "./CouponSearchModal";
import QrcodeAuthPay from "./QrcodeAuthPay";
import moment from 'moment';
import { api } from "../../util/axios";
import PayLoading from "./PayLoading";
import MemberAuthPay from "./MemberAuthPay";
import ResultModal from "./ResultModal";
import GiftCardAuthPay from "./GiftCardAuthPay";

const getCouponType = (type) => {

    if ("VOUCHER" == type) {
        return "代金券";
    }
    if ("DISCOUPON" == type) {
        return "折扣券";
    }
    return null;
}

export default (props) => {

    const [payType, setPayType] = useState("MC_AUTH_CODE");
    const [searchMember, setSearchMember] = useState(false);
    const [member, setMember] = useState(false);
    const [amount, setAmount] = useState(null);
    const [showMpass, setShowMpass] = useState(false);
    const [coupon, setCoupon] = useState(null);
    const [searchCoupon, setSearchCoupon] = useState(false);
    const amountForm = useRef(null);
    const [qrcodeAuthVisable, setQrcodeAuthVisable] = useState(false);
    const [memberAuthPay, setMemberAuthPay] = useState(false);
    const [payLoadingVisable, setPayLoadingVisable] = useState(false);
    const [resultData, setResultData] = useState(null);
    const [giftCardAuthPay, setGiftCardAuthPay] = useState(false);

    // {
    //     message:"支付成功",
    //     orderStatus:"SUCCESS",
    //     amount:300,
    //     amountFree:60,
    //     amountReal:240,
    //     orderNo:"P210520164633100229"
    // }

    const getPayAmount = () => {

        if (!amount) {
            return null;
        }
        if (!coupon) {
            return amount;
        }

        if (!validCoupon()) {
            return amount;
        }
        //折扣券
        if (coupon.type == "DISCOUPON") {
            return (amount * coupon.amount * 0.1).toFixed(2);
        }
        //代金券
        if (coupon.type == "VOUCHER") {
            return (amount - coupon.amount).toFixed(2);
        }
        return amount;


    }

    const validCoupon = () => {

        if (!amount || !coupon) {
            return true;
        }

        if (coupon.fillAmount) {
            return amount >= coupon.fillAmount;
        }
        return false;


    }

    const getAmountValue = (value) => {
        if (value == ".") {
            return "";
        }
        if (value) {
            const sarr = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "."];
            const end = value.substring(value.length - 1, value.length);
            const find = sarr.find(x => x == end);
            if (!find) {
                return value.length == 1 ? "" : value.substring(0, value.length - 1);
            }
        }

        if (value && value.startsWith("00")) {
            return value.substring(1, value.length);
        }

        // if(sarr)
        if (value && value.indexOf(".") != -1) {

            const st = value.substring(0, value.length - 1);
            const end = value.substring(value.length - 1, value.length);

            if (end == "." && st.indexOf(".") != -1) {
                return st;
            }
            console.log("end=>", value.split(".")[1]);
            if (value.split(".")[1].length > 2) {
                return st;
            }
        }

        // if (value && value.indexOf(".") != -1) {

        // }
        return value;
    }

    //卡券是否可用
    const couponUsable = validCoupon();
    //实际金额
    const realAmount = getPayAmount();

    const doPay = (params) => {

        setPayLoadingVisable(true);
        api("payment").post({
            ...params,
            amount: amount,
            couponCardId: (coupon && couponUsable) ? coupon.id : null,
            memberId: member ? member.id : null
        }).then(ret => {

            // message.success(ret.msg);
            if (ret.code == 200) {

                const data = ret.data;

                setResultData(ret.data);


            }

        }).finally(() => {
            setPayLoadingVisable(false);
        })

    }

    useEffect(() => {

        setTimeout(() => {
            document.getElementById("amount-input").focus();
        }, 200);

    }, []);

    return (
        <div>
            <Row>
                <Col span={8}>
                    <Card title="收款金额" bordered={false}>
                        <Form layout="vertical" ref={ref => amountForm.current = ref} initialValues={{
                            amount: undefined
                        }}>
                            <Form.Item
                                label="收款金额"
                                name="amount"
                                rules={[{ required: true, message: "请输入收款金额" }]}

                                getValueFromEvent={e => {

                                    // const value = e.target.value;
                                    const value = getAmountValue(e.target.value);

                                    const amountValue = value ? parseFloat(value).toFixed(2) : null;
                                    // if(amountValue && amountValue >= 100000){
                                    //     return;
                                    // }
                                    setAmount(amountValue);



                                    return value;

                                }}

                            >
                                <Input
                                    autoFocus={true}
                                    style={{ width: "100%" }}
                                    placeholder="收款金额"
                                    id="amount-input"
                                    maxLength={100000}

                                // addonBefore={"¥"}


                                />
                            </Form.Item>
                            <Form.Item>
                                <Row style={{ backgroundColor: "#f1f1f1", padding: 1 }}>
                                    {
                                        [1, 2, 3, 4, 5, 6, 7, 8, 9, 0, ".", "清空"].map((num, index) => {

                                            return (
                                                <Col
                                                    key={index}
                                                    span={8}
                                                    style={{ padding: 1 }}
                                                    onClick={() => {
                                                        if ("清空" == num) {
                                                            amountForm.current.setFieldsValue({
                                                                amount: null
                                                            });
                                                            setAmount(null);
                                                            return;
                                                        }
                                                        const v = amountForm.current.getFieldValue("amount");

                                                        if (num == "." && !v) {
                                                            return;
                                                        }

                                                        if (`${v ? v : ""}`.indexOf(".") != -1) {
                                                            if ("." == num) {
                                                                return;
                                                            }
                                                            if (`${v ? v : ""}`.split(".")[1].length >= 2) {
                                                                return;
                                                            }

                                                        }

                                                        const value = `${v ? v : ""}${num}`;

                                                        amountForm.current.setFieldsValue({
                                                            amount: value
                                                        });
                                                        setAmount(value ? parseFloat(value).toFixed(2) : null);
                                                    }}
                                                >
                                                    <div className="amount-num-item" style={{ fontWeight: "bold" }}>
                                                        {num}
                                                    </div>
                                                </Col>
                                            )
                                        })
                                    }

                                </Row>
                            </Form.Item>
                        </Form>
                    </Card>
                </Col>
                <Col span={8}>
                    <Card title="支付方式" bordered={false}>
                        <Form layout="vertical">
                            {/* <Form.Item>
                                <Input placeholder="条形码/付款码" />
                            </Form.Item> */}
                            <Form.Item label="会员">
                                {
                                    member ? (
                                        <div style={{
                                            width: "100%",
                                            padding: 10,
                                            backgroundColor: "#f0f0f0",
                                            borderRadius: 5,
                                            border: "1px solid #E7C77B"

                                        }}>
                                            <div className="text-overflow" style={{ marginBottom: 5 }}>姓名：{member.realName}</div>
                                            <div className="text-overflow" style={{ marginBottom: 5 }}>手机：{member.phone}</div>
                                            <div className="text-overflow" style={{}}>余额：{member.balance}</div>
                                            <div style={{ position: "absolute", top: 10, right: 10, height: 64, display: "flex", alignItems: "center" }}>
                                                <Popconfirm title="是否删除" onConfirm={() => {
                                                    setMember(null);
                                                }}>
                                                    <DeleteOutlined />
                                                </Popconfirm>

                                            </div>

                                        </div>
                                    ) : (
                                        <Button block icon={<UserOutlined />} onClick={() => {

                                            setSearchMember(true)
                                        }}>
                                            选择会员
                                            <DoubleRightOutlined />
                                        </Button>
                                    )
                                }

                            </Form.Item>
                            <Form.Item label="卡券">
                                {
                                    coupon ? (
                                        <div
                                            style={{
                                                width: "100%",
                                                // padding: 10,
                                                backgroundColor: "#fff",
                                                borderRadius: 5,
                                                position: "relative"

                                            }}
                                            className="coupon-card"
                                        >
                                            <div className="coupon-card-left">
                                                <div>
                                                    <div style={{ textAlign: "center", color: "red" }}>
                                                        <span>{coupon.type == "VOUCHER" ? "¥" : ""}</span>
                                                        <span style={{ color: "red", fontWeight: "bold", fontSize: 24, marginLeft: 3 }}>{coupon.amount}</span>
                                                        <span>{coupon.type == "DISCOUPON" ? "折" : ""}</span>
                                                    </div>
                                                    <div style={{ fontSize: 8 }}>
                                                        {coupon.fillAmount ? `满${coupon.fillAmount}元可用` : ""}
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className="coupon-card-right"
                                            >
                                                <div style={{ marginBottom: 10 }}>
                                                    {/* <span  >{getCouponType(coupon.type)}</span> */}
                                                    <span style={{ fontSize: 12 }}>{coupon.type == "VOUCHER" ? "代金券" : "折扣券"}</span>
                                                </div>
                                                <div style={{ fontSize: 12 }}>
                                                    {moment(coupon.startTime).format("YYYY.MM.DD")}
                                                    -
                                                    {moment(coupon.expiredTime).format("YYYY.MM.DD")}
                                                </div>

                                            </div>
                                            {/* <div className="coupon-name" >{coupon.name}</div>
                                            <div style={{ color: "#888",padding:10 }}>编号：{coupon.orderNo}</div> */}
                                            {/* <div style={{ color: "#888" }}>余额：{member.balance}</div> */}

                                            {
                                                !couponUsable ? (
                                                    <div style={{
                                                        position: "absolute",
                                                        top: 0,
                                                        left: 0,
                                                        width: "100%",
                                                        backgroundColor: "rgba(255,255,255,0.5)",
                                                        height: "100%",
                                                        textAlign: "center"
                                                    }}
                                                    >
                                                        <img src="/img/bky.png" />
                                                    </div>
                                                ) : null
                                            }

                                            <div style={{ position: "absolute", top: 0, right: 10, height: 64, display: "flex", alignItems: "center" }}>
                                                <Popconfirm title="是否删除" onConfirm={() => {
                                                    setCoupon(null);
                                                }}>
                                                    <DeleteOutlined />
                                                </Popconfirm>
                                            </div>
                                        </div>
                                    ) : (
                                        <Button block icon={<ProfileOutlined />} onClick={() => {
                                            if (!amount) {
                                                message.error("收款金额不正确")
                                                return;
                                            }
                                            setSearchCoupon(true);
                                        }}>
                                            输入卡券
                                            <DoubleRightOutlined />
                                        </Button>
                                    )
                                }
                            </Form.Item>
                            <Form.Item label="付款方式">
                                {/* <Space size={10}> */}

                                <Row>

                                    <Col span={8}>

                                        <div className="pay-type-item" style={{ marginLeft: 0 }} onClick={() => {

                                            if (!amount) {
                                                message.error("收款金额不正确")
                                                return;
                                            }

                                            setMemberAuthPay(true);
                                        }}>
                                            <div className="bt">
                                                <QrcodeOutlined />
                                                <span>会员扫码</span>
                                                <span className="pay-type-item-desc">
                                                    打开会员小程序
                                                </span>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col span={8}>
                                        <div className="pay-type-item" onClick={() => {

                                            if (!amount) {
                                                message.error("收款金额不正确")
                                                return;
                                            }
                                            setQrcodeAuthVisable(true);
                                        }}>
                                            <div className="bt">
                                                <ScanOutlined />
                                                <span>扫码支付</span>
                                                <span className="pay-type-item-desc">
                                                    支持微信/支付宝
                                                </span>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col span={8}>
                                        <div className="pay-type-item" onClick={() => {
                                            amountForm.current.validateFields().then(values => {
                                                if (!member) {
                                                    message.error("请先选择会员")
                                                    return;
                                                }
                                                setShowMpass(true)
                                            });

                                        }}>
                                            <div className="bt">
                                                <CreditCardOutlined />
                                                <span>会员密码</span>
                                                <span className="pay-type-item-desc">
                                                    支付密码支付
                                                </span>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col span={8}>
                                        <div className="pay-type-item" onClick={() => {

                                            if (!amount) {
                                                message.error("收款金额不正确")
                                                return;
                                            }

                                            setGiftCardAuthPay(true);
                                        }}>
                                            <div className="bt">
                                                <GiftFilled />
                                                <span>礼品卡&nbsp;&nbsp;</span>
                                                <span className="pay-type-item-desc">
                                                    打开会员小程序
                                                </span>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                {/* </Space> */}




                            </Form.Item>

                        </Form>
                    </Card>
                </Col>
                <Col span={8}>
                    <Card title="收款详情" bordered={false}>
                        <List>
                            <List.Item extra={<div className="pay-detail-right">
                                {`${member ? `${member.phone}` : ""}`}
                            </div>}>会员:</List.Item>
                            <List.Item extra={<div className="pay-detail-right">{amount ? amount : ""}</div>}  >应收:</List.Item>
                            <List.Item extra={<div className="pay-detail-right">{realAmount ? realAmount : ""}</div>}  >实收:</List.Item>

                        </List>
                    </Card>
                </Col>
            </Row>
            <MemberSearchModal
                visible={searchMember}
                onCancel={() => setSearchMember(false)}
                onOk={(value) => {
                    setSearchMember(false);
                    setMember({ ...value });
                }}
            />
            <CouponSearchModal
                visible={searchCoupon}
                onCancel={() => setSearchCoupon(false)}
                onOk={(value) => {
                    setSearchCoupon(false);
                    setCoupon({ ...value });
                }}
            />
            <MPasswordModal
                visible={showMpass}
                onCancel={() => setShowMpass(false)}
                onOk={(password) => {
                    setShowMpass(false);
                    console.log("password=>", password);
                    doPay({
                        amount: amount,
                        type: "MC_PWD",
                        password: password
                    })
                }}
            />

            <QrcodeAuthPay
                visible={qrcodeAuthVisable}
                onCancel={() => setQrcodeAuthVisable(false)}
                onOk={(authCode) => {


                    setQrcodeAuthVisable(false);

                    doPay({
                        amount: amount,
                        type: "QRCODE_AUTH",
                        authCode: authCode
                    })





                }}
            />
            <MemberAuthPay
                visible={memberAuthPay}
                onCancel={() => setMemberAuthPay(false)}
                onOk={(authCode) => {
                    setMemberAuthPay(false);
                    doPay({
                        amount: amount,
                        type: "MC_AUTH_CODE",
                        authCode: authCode
                    })
                }}
            />
            <GiftCardAuthPay
                visible={giftCardAuthPay}
                onCancel={() => setGiftCardAuthPay(false)}
                onOk={(authCode) => {
                    setGiftCardAuthPay(false);
                    doPay({
                        amount: amount,
                        type: "GIFT_CARD_AUTH_CODE",
                        authCode: authCode
                    })
                }}
            />
            <PayLoading
                visible={payLoadingVisable}
            />
            <ResultModal
                data={resultData}
                onOk={() => {
                    props.onClose();
                }}
            />
        </div>
    )
}