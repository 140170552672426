import { Modal } from "antd";
import React, { useState } from "react";
import AuthLoading from "../../components/AuthLoading";

export default ({visible}) => {


    return (
        <Modal
            title={"支付中..."}
            visible={visible}

            footer={null}
            closeIcon={false}
            closable={false}
            centered={true}
            width={300}
            destroyOnClose={true}
        >

            <div style={{marginBottom:10}}>
                <AuthLoading />
            </div>
            <div style={{textAlign:"center"}}>
                <p style={{color:"#888"}}>正在支付中,请耐心等待...</p>
            </div>

        </Modal>
    )
}

