import { Form, Col, Tabs } from "antd";
import React, { useState } from "react";
import ContentLayout from "../../layout/ContentLayout";
import DataTablePage from "../public/DataTablePage";
import MemberQuerySelect from "../public/MemberQuerySelect";
import Recharge from "./recharge";
import Records from "./records";
export default ({login}) => {

    const [active, setActive] = useState("recharge");

    return (
        <div style={{ paddingTop: 10 }}>
            <Tabs tabPosition="left" destroyInactiveTabPane={true} activeKey={active} onChange={key => setActive(key)}>
                <Tabs.TabPane tab="会员充值" key="recharge">
                    <Recharge
                        jumpTabTo={(key) => {
                            setActive(key);
                        }}
                    />
                </Tabs.TabPane>
                <Tabs.TabPane tab="充值记录" key="records" style={{ padding: 10 }}>
                    <Records login={login} />
                </Tabs.TabPane>
            </Tabs>
        </div>

    )

    // return (
    //     <ContentLayout>

    //         <DataTablePage

    //             topComponents={<>

    //                 <Col span={8}>
    //                     <Form.Item>
    //                         <MemberQuerySelect />
    //                     </Form.Item>
    //                 </Col>


    //             </>}

    //             columns={[
    //                 {title:"会员",key:"member"},
    //                 {title:"充值金额",key:"amount",dataIndex:"amount"},
    //                 {title:"操作员",key:"shopUserName",dataIndex:"shopUserName"},
    //                 {title:"充值时间",key:"createTime",rend}
    //             ]}

    //         />

    //     </ContentLayout>
    // )
}