import { Modal,Form,Input } from "antd";
import React, { useRef, useState } from "react";
import EditModal from "../../components/EditModal";
import { api } from "../../util/axios";


export default ({visible=false,onCancel,onOk}) => {

    const layout = {
        labelCol: { span: 4 },
        wrapperCol: { span: 16 }
    }
  
    const form = useRef(null);
    
    const [loading,setLoading] = useState(false);

    const queryOrder = (orderNo)=>{

        setLoading(true);
        api("order/refund/query")
        .get({orderNo:orderNo})
        .then(ret=>{
            if(ret.code == 200){
                onOk(ret.data);
            }
        })
        .finally(()=>{
            setLoading(false);
        })


    }

    return (
        <EditModal
            title="退款"
            visible={visible}
            // footer={null}
            style={{
                minWidth: 300
            }}
            onCancel={onCancel}
            onOk={()=>{
                form.current.validateFields().then(values=>{

                    queryOrder(values.orderNo);

                }).catch(()=>{

                })
            }}
        >
            <Form {...layout} ref={ref=>form.current = ref} onFinish={values=>{
                queryOrder(values.orderNo);
            }}>
                <Form.Item label="订单号" name="orderNo" rules={[{ required: true,message:"订单号不能为空" }]}>
                    <Input placeholder="订单号/扫码录入" autoFocus={true} onPressEnter={e=>{

                        form.current.submit();

                    }} />
                </Form.Item>
                {/* <Form.Item wrapperCol={{
                offset: 4,
                span:16
            }}>
                <div style={{display:"flex",justifyContent:"flex-end"}}>
                    <Button block type="primary" htmlType="submit">查询</Button>
                </div>

            </Form.Item> */}
            </Form>
        </EditModal>
    )
}
