import React, {useState,useRef } from "react";
import { Tabs, Col, DatePicker, Form, Checkbox, Button, message } from "antd";
import DataTablePage from "../public/DataTablePage";
import config from "../../config"
import { axiosRequest } from '../../util/axios';
import PrintTicketModal from "./PrintTicketModal";

const PaymentStats = ({login}) => {

    const [loading, setLoading] = useState(false);
    const tableRef = useRef(null);

    const [printOrder,setPrintOrder] = useState(null);

    return (
        <div style={{ padding: 12 }}>
            <DataTablePage
                apiUrl="stats/payment"
                pagination={false}
                bordered={false}
                tableRef={ref => tableRef.current = ref}
                onValuesChange={values => {
                    const payTypes = values.payTypes?values.payTypes.join(","):"";
                    const value = {
                        ...values,
                        payTypes:payTypes
                        
                    }
                    tableRef.current.loadData(value);
                }}

                // queryValueTrans={value=>{

                //     //console.log("value=>",value);
                //     const payTypes = value.payTypes?value.payTypes.join(","):"";
                //     return {
                //         ...value,
                //         payTypes:payTypes
                        
                //     }
                // }}
                
                topComponents={<>

                    <Col span={12}>
                        <Form.Item label="日期" name="times">
                            <DatePicker.RangePicker
                                picker="date"
                                style={{ width: "100%" }}
                                disabledDate={(date) => {
                                    var stime = date.toDate().getTime();
                                    if (stime > new Date().getTime()) {
                                        return true;
                                    }
                                    return false;
                                }}

                            />
                        </Form.Item>
                    </Col>
                    <Col span={12} style={{marginBottom:10}}>
                        <Form.Item name="payTypes" label="支付方式">
                            <Checkbox.Group>
                                <Checkbox value="WX_PAY">微信</Checkbox>
                                <Checkbox value="ALI_PAY">支付宝</Checkbox>
                                <Checkbox value="MEMBER_CARD">会员卡</Checkbox>
                            </Checkbox.Group>
                        </Form.Item>
                    </Col>
                    <Col span={12} style={{display:"flex",justifyItems:"flex-end"}}>

                        <Button type="primary" htmlType="submit">查询</Button>
                        <Button type="default" loading={loading} style={{ marginLeft: 15 }} onClick={async () => {

                            const params = await tableRef.current.getParams();
                            // console.log("patams=>", params);
                            setLoading(true)
                            axiosRequest({
                                url: `${config.baseApiUrl}/stats/payment/downloand`,
                                method: 'GET',
                                params: params
                            }).then(ret => {
                                // console.log(ret.headers);
                                const blob = new Blob([ret.data], { type: 'application/octet-stream' })
                                const blobURL = window.URL.createObjectURL(blob)
                                // 创建a标签，用于跳转至下载链接
                                const tempLink = document.createElement('a')
                                tempLink.style.display = 'none'
                                tempLink.href = blobURL;
                                tempLink.setAttribute('download', `${decodeURIComponent(ret.headers["download-filename"])}`);
                                // 兼容：某些浏览器不支持HTML5的download属性
                                if (typeof tempLink.download === 'undefined') {
                                    tempLink.setAttribute('target', '_blank')
                                }
                                // 挂载a标签
                                document.body.appendChild(tempLink);
                                tempLink.click()
                                document.body.removeChild(tempLink);
                                // 释放blob URL地址
                                window.URL.revokeObjectURL(blobURL);
                                message.success("下载成功");
                            }).finally(() => {
                                setLoading(false);
                            })
                        }} >导出</Button>
                    </Col>

                </>}
                queryValueTrans={values => {

                    const [startDate, endDate] = values.times ? values.times : [];
                    return {
                        ...values,
                        startTime: startDate ? startDate.toDate().getTime() : undefined,
                        endTime: endDate ? endDate.toDate().getTime() : undefined,
                        times: undefined,
                        payTypes: values.payTypes ? values.payTypes.join(",") : ""
                    }
                }}
                columns={[
                    { title: "日期", key: "dateTime", dataIndex: "dateTime", width: 120 },
                    { title: "交易笔数", key: "payCount", dataIndex: "payCount" },
                    { title: "交易金额", key: "amountAll", dataIndex: "amountAll" },
                    { title: "优惠金额", key: "amountFreeAll", dataIndex: "amountFreeAll" },
                    { title: "实收金额", key: "amountRealAll", dataIndex: "amountRealAll" },
                    { title: "退款笔数", key: "refundCount", dataIndex: "refundCount" },
                    { title: "退款金额", key: "amountRefundAll", dataIndex: "amountRefundAll" },
                    { title: "结算金额", key: "amountSettAll", dataIndex: "amountSettAll" },
                    // {title:"操作",key:"otp",render:r=>{

                    //     if(r.dateTime  == "合计"){
                    //         return null;
                    //     }
                    //     return (
                    //         // <Button type="primary" onClick={()=>setPrintOrder({...r})}>打印</Button>
                    //     )
                    // }}
                ]}


            />
             <PrintTicketModal order={printOrder} login={login} onCancel={()=>setPrintOrder(null)}/>
        </div>
    )

}

export default ({login}) => {

    return (
        <div style={{  paddingTop:10}}>

            <Tabs tabPosition="left">
                <Tabs.TabPane tab="收款统计" key="payment" style={{ padding: 10 }}>
                    <PaymentStats login={login} />
                </Tabs.TabPane>
                {/* <Tabs.TabPane tab="门店统计" key="shop"></Tabs.TabPane> */}
            </Tabs>

        </div>
    )
}