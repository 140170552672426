import React, { Component } from "react";
import { Layout } from "antd";

export default class ContentLayout extends Component {


    render (){

        const props = {
            ...this.props,
            className:`site-layout-background site-layout-content ${this.props.className?this.props.className:""}`
        }

        return (
            <Layout.Content {...props} style={{
                minHeight:"calc(100% - 20px)",
                position:"relative",
                ...this.props.style
                
            }}
            >
                {this.props.children}
            </Layout.Content>
        )
    }

}