import { Button, Card, Col, Drawer, Form, Input, Row, Statistic, Tag,Modal } from "antd";
import React, { useEffect, useState } from "react";
import ContentLayout from "../../layout/ContentLayout"
import {
    PayCircleOutlined,
    MoneyCollectOutlined,
    CreditCardOutlined,
} from '@ant-design/icons';
import DataTablePage from "../public/DataTablePage"
import PayPanel from "./PayPanel";
import { api } from "../../util/axios";
import moment from "moment";
import PrintTicketModal from "../record/PrintTicketModal";
export default ({login}) => {


    const [visableDrawer, setVisableDrawer] = useState(false);
    const [todayData, setTodayData] = useState({ payCount: 0, amountTotal: 0 });
    const [reloadData, setReloadData] = useState(true);
    const [printOrder,setPrintOrder] = useState(null); 

    const loadTodayData = () => {

        api("payment/today/data").get().then(ret => {
            if (ret.code == 200) {
                setTodayData(ret.data);
            }
        })
    }



    useEffect(() => {
        loadTodayData();
    }, []);

    return (
        <ContentLayout>
            <Row style={{ marginBottom: 15,border:"1px solid #f0f0f0",padding:10,borderRadius:2 }}>
                <Col span={8}>
                    <Statistic title="今日收款金额" value={todayData.amountTotal.toFixed(2)} />
                </Col>
                <Col span={8}>
                    <Statistic title="今日交易笔数" value={todayData.payCount} />
                </Col>
                <Col span={8} style={{ display: "flex", alignItems: "center", justifyContent: "center" }} >
                    <Button  icon={<MoneyCollectOutlined />} type="primary" onClick={() => setVisableDrawer(!visableDrawer)} >立即收款</Button>
                </Col>
            </Row>
            <div style={{ marginBottom: 10 }}>最近10条收款记录</div>
            <DataTablePage
                bordered={false}
                pagination={false}
                apiUrl="payment/recent/order"
                reloadData={reloadData}
                onLoadDataEnd={() => setReloadData(false)}
                tableProps={{
                    // size:"small"
                }}
                columns={[
                    { title: "订单号", key: "upOrderNo", dataIndex: "orderNo" },
                    {
                        title: "金额", key: "amount",width:150, render: r => {
                            return (
                                <div style={{fontSize:12}}>
                                    <div>应收：¥{r.amount.toFixed(2)}</div>
                                    {/* <div>优惠：¥{r.amountFree.toFixed(2)}</div> */}
                                    <div>实收：¥{r.amountTrue.toFixed(2)}</div>
                                    {r.amountRefund ? (
                                        <div>
                                            <a style={{ color: "red" }}>退款：¥{r.amountRefund.toFixed(2)}</a>
                                        </div>
                                    ) : null}
                                </div>
                            )
                        }
                    },
                    // { title: "付款", key: "amountReal", dataIndex: "amountReal" },
                    // { title: "优惠", key: "amountFree", dataIndex: "amountFree" },
                    // {
                    //     title: "方式", key: "payType",width:100 ,render: r => {

                    //         const payTypeMap = {
                    //             "MEMBER_CARD": "会员卡",
                    //             "WX_PAY": "微信",
                    //             "ALI_PAY": "支付宝",
                    //             "GIFT_CARD":"礼品卡"
                    //         };
                    //         return payTypeMap[`${r.payType}`]

                    //     }
                    // },
                    {
                        title: "方式", key: "payType",width:100, render: r => {

                            const getMemberType = (m)=>{
                                 if(!m.memberId){
                                    return "会员卡";
                                 }
                                 return (
                                     <div>
                                         <div>会员卡</div>
                                         <div style={{fontSize:12}}>{`${m.memberType == "VIP"?"金融会员":"储值会员"}`}</div>
                                         <div style={{fontSize:12}}>{m.memberPhone}</div>
                                     </div>
                                 )
                            }

                            const payTypeMap = {
                                "MEMBER_CARD": getMemberType(r),
                                "WX_PAY": "微信",
                                "ALI_PAY": "支付宝",
                                "GIFT_CARD":"礼品卡"
                            };
                            return payTypeMap[`${r.payType}`]

                        }
                    },
                    {
                        title: "状态", key: "orderStatus", render: r => {

                            const statusMap = {
                                "0": <Tag color="processing">未支付</Tag>,
                                "1": <Tag color="green">成功</Tag>,
                                "2": <Tag color="error">失败</Tag>,
                                "3": <Tag>超时</Tag>,
                                "4": <Tag>取消</Tag>
                            }

                            return statusMap[`${r.payStatus}`]

                        }
                    },
                    { title: "收银员", key: "userName", dataIndex: "userName" },
                    { title: "收款时间", key: "createTime", render: r => moment(r.createTime).format("YYYY-MM-DD HH:mm") },
                    // {
                    //     title: "备注", key: "remark", width: 100, render: r => {
                    //         if (r.isRemark) {
                    //             return (
                    //                 <div style={{ maxWidth: 100 }}>
                    //                     {
                    //                         r.remarkImage ? <div style={{ marginBottom: 5 }}>
                    //                             <a onClick={() => {
                    //                                 Modal.info({
                    //                                     icon: null,
                    //                                     title: "图片预览",
                    //                                     content: <div style={{textAlign:"center"}}>
                    //                                         <img src={r.remarkImage} style={{ maxWidth: 300, maxHeight: 300, objectFit: "contain" }} />

                    //                                     </div>
                    //                                 })
                    //                             }}>
                    //                                 <img src={r.remarkImage} style={{ width: 30, height: 30, objectFit: "contain" }} />
                    //                             </a>

                    //                         </div> : null
                    //                     }
                    //                     <div style={{ fontSize: 12 }}>{r.remarkText}</div>

                    //                 </div>
                    //             )
                    //         }

                    //         return <div></div>
                    //     }
                    // },
                    {title:"操作",key:"opt",render:r=>{
                        return r.payStatus == 1?  <Button size="small" onClick={()=>setPrintOrder({...r})}>打印</Button>:null;
                    }}
                ]}
            />
            <Drawer
                visible={visableDrawer}
                destroyOnClose={true}
                width={window.document.body.clientWidth - 105}
                onClose={() => {
                    setVisableDrawer(false);
                    setReloadData(true);
                    loadTodayData();
                }}
            // title="收款"
            >
                <PayPanel onClose={() => {
                    setVisableDrawer(false);
                    setReloadData(true);
                    loadTodayData();
                }} />
            </Drawer>

            {/* <div style={{height:500}}></div> */}

            <PrintTicketModal login={login} order={printOrder} onCancel={()=>setPrintOrder(null)}/>
        </ContentLayout>

    )
}