import React, { Component } from 'react';
import { Table } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
export default class DataTable extends Component {





    render() {
        const { onPageChange, loading, scrollY, scrollX, dataTotal, dataSource, pagination, pageSizeOptions,pageNo,pageSize } = this.props;
        return (
            <Table
               
                rowKey={row => row.id}
                {...this.props}
                loading={{ spinning: loading, indicator: <LoadingOutlined /> }}
                pagination={!pagination ? false : {
                    showSizeChanger: true,
                    total: dataTotal ? dataTotal : dataSource.length,
                    pageSize:pageSize,
                    current:pageNo,
                    pageSizeOptions: pageSizeOptions ? pageSizeOptions : ["15", "30", "60", "100", "150"],
                    defaultPageSize: 15,
                    showTotal: (total) => `共${total}条数据`,
                    onShowSizeChange: (pageNo, pageSize) => {
                        // if (onPageChange) {
                        //     onPageChange(pageNo, pageSize)
                        // }
                    },
                    onChange: (pageNo, pageSize) => {
                        if (onPageChange) {
                            onPageChange(pageNo, pageSize)
                        }
                    }
                }}
                
                // size="small"
               
            // scroll={{
            //     y:scrollY?scrollY:0,
            //     x:scrollX?scrollX:0
            // }}


            />
        )

    }


}