import Modal from "antd/lib/modal/Modal";
import React, { useState } from "react";
import moment from "moment";
import { withConfirm } from "antd/lib/modal/confirm";
import { Table } from "antd";



const payTypeMap = {
    "MEMBER_CARD": "会员卡",
    "WX_PAY": "微信支付",
    "ALI_PAY": "支付宝"
};

export default ({ order, login, onCancel }) => {


    const getPayType = (o) => {
        if (!o) {
            return null;
        }

        if (o.payType == "MEMBER_CARD") {

            return `会员卡- (${o.memberTypeName} ${o.memberPhone})`

        }
        return payTypeMap[order.payType];
    }

    return (
        <Modal
            visible={order ? true : false}
            title="小票打印"
            cancelText="取消"
            okText="确定"
            centered={true}
            onOk={() => {

                // window.print();

                const win = window.document.getElementById("print-iframe").contentWindow;

                win.document.body.innerHTML = window.document.getElementById("print-content").innerHTML;


                win.print();

                //    alert(s);

                onCancel();

            }}
            onCancel={onCancel}
        >
            {
                order ? (
                    <div id="print-content" style={{ maxWidth: 640 }}>
                        {/* <div style={{textAlign:"center",marginBottom:5}}>
                           
                        </div> */}
                        <div style={{ textAlign: "center", marginBottom: 20, width: "100%" }}>
                            <div style={{ width: "100%", display: "flex", alignItems: "center", justifyItems: "center" }}><img src={login.shopImgUrl} style={{ width: 30, height: 30, borderRadius: 15 }} /> <span style={{ marginLeft: 10, fontSize: 16, fontWeight: "bold" }}>{login.shopName}</span></div>
                        </div>
                        <div style={{marginBottom:20}}>
                            日期: {order?order.dateTime:""}
                        </div>
                        <Table
                            pagination={false}
                            showHeader={false}
                            bordered={true}
                            style={{borderColor:"#000"}}
                            columns={[
                                {title:"支付",key:"t",dataIndex:"title",align:"center"},
                                {title:"内容",key:"v",dataIndex:"value"}
                            ]}

                            dataSource={[
                                {title:"全部:",value:(
                                    <div>
                                        <div>金额：{order.amountAll}</div>
                                        <div>笔数：{order.payCount}</div>
                                        <div>退款：{order.amountRefundAll}</div>
                                    </div>
                                )},
                                {title:"支付宝:",value:(
                                    <div>
                                        <div>金额：{order.aliPayAmount}</div>
                                        <div>笔数：{order.aliPayCount}</div>
                                       
                                    </div>
                                )},
                                {title:"微信:",value:(
                                    <div>
                                        <div>金额：{order.wxPayAmount}</div>
                                        <div>笔数：{order.wxPayCount}</div>
                                        
                                    </div>
                                )},
                                {title:"会员卡:",value:(
                                    <div>
                                        <div>金额：{order.mcPayAmount}</div>
                                        <div>笔数：{order.mcPayCount}</div>
                                       
                                    </div>
                                )},
                                {title:"储值会员:",value:(
                                    <div>
                                        <div>金额：{order.v2mcPayAmount}</div>
                                        <div>笔数：{order.v2mcPayCount}</div>
                                       
                                    </div>
                                )},
                                {title:"金融会员:",value:(
                                    <div>
                                        <div>金额：{order.v3mcPayAmount}</div>
                                        <div>笔数：{order.v3mcPayAmount}</div>
                                       
                                    </div>
                                )}

                            ]}
                        >

                        </Table>
                    </div>
                ) : null
            }

            <iframe id="print-iframe" style={{ maxWidth: 640, display: "none" }}></iframe>


        </Modal>
    )


}