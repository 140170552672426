import { Button, Col, DatePicker, Form, Input, Tag } from "antd";
import React, { useEffect, useState } from "react";
import DataTablePage from "../public/DataTablePage";
import MemberQuerySelect from "../public/MemberQuerySelect";
import moment from "moment";
import FormEditModel from "../public/FormEditModal";
import { api } from "../../util/axios";


const QueryMemberInvoice = ({ memberId, value, onChange }) => {


    const [data, setData] = useState({});

    useEffect(() => {

        api("member/invoce/query").get({ memberId: memberId }).then(ret => {
            if (ret.code == 200) {
                setData(ret.data)
            }
        })


    }, [memberId]);

    if(!data){
        return null;
    }

    return (
        <>
            <div style={{marginBottom:5}}>


                <Input placeholder="开票金额" value={value} onChange={onChange} max={data.amount}  />
            </div>
            <div>
                <div>
                    <span>开票总额度：{data.total}</span>
                </div>
                <div>
                    <span>已开票金额：{data.invoiceAmount}</span>
                </div>
                <div>
                    <span>未开票金额：{data.amount}</span>
                </div>

            </div>

        </>
    )
}


export default ({ }) => {


    const [formData, setFormData] = useState(null);
    const [reload,setReload] = useState(true);


    return (
        <>
            <DataTablePage
                apiUrl="member/invoice/records"
                topComponents={<>
                    <Col span={8}>
                        <Form.Item name="memberId">
                            <MemberQuerySelect />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item>
                            <Button type="primary" htmlType="submit">查询</Button>
                            <Button type="default" style={{ marginLeft: 15 }} onClick={() => setFormData({})} >添加开票记录</Button>
                        </Form.Item>
                    </Col>

                </>}
                tableProps={{
                    bordered: false
                }}
                columns={[
                    { title: "会员", key: "memberName", dataIndex: "memberName" },
                    { title: "开票金额", key: "amount", dataIndex: "amount" },
                    
                    {title:"开票时间",key:"createTime",render:r=>moment(r.createTime).format("YYYY-MM-DD HH:mm:ss")}

                ]}
                reloadData={reload}
                onLoadDataEnd={()=>{
                    setReload(false);
                }}

            />
            <FormEditModel
                modalProps={{
                    title: "开票"
                }}
                formData={formData}
                apiUrl={"member/invoice/add"}
                onCancel={()=>setFormData(null)}
                onOk={()=>{
                    setReload(true);
                    setFormData(null);
                }}
                formItems={
                    <>
                        <Form.Item name="memberId" label="会员" rules={[{ required: true }]} >
                            <MemberQuerySelect />
                        </Form.Item>
                        <Form.Item noStyle shouldUpdate={(p, c) => p.memberId != c.memberId}>
                            {
                                ({ getFieldValue }) => {

                                    const memberId = getFieldValue("memberId");
                                    if (memberId) {
                                        return (
                                            <Form.Item name="amount" label={"开票金额"} rules={[{ required: true }]}>
                                                <QueryMemberInvoice memberId={memberId} />
                                            </Form.Item>
                                        )
                                    }
                                }
                            }
                        </Form.Item>
                    </>
                }

            />
        </>
    )
}