
/**
 * 配置
 */
const CONFIG = {
    development: {
        // baseApiUrl: "//pclient-api-dev.ajdx.com.cn",
        baseApiUrl:"http://127.0.0.1:9082",
        // baseApiUrl:"http://192.168.3.7:9082", 
        // baseApiUrl: "http://ba-api-dev.ajdx.com.cn",
        baseImageUrl: ""
    },
    production: {
        baseApiUrl: "//pclient-api.ajdx.com.cn",
        baseImageUrl: ""
    }

}

// console.log(process.env.NODE_ENV);

const config = CONFIG[`${process.env.NODE_ENV}`]

export default config;


const SYS_CONFIG = {
    sysName: "",
    sysLogo: ""
};

export const getSysConfig = () => {
    return SYS_CONFIG;
};

export const initSysConfig = (values) => {

    Object.keys(values).forEach(k => SYS_CONFIG[`${k}`] = values[`${k}`]);

    // document.title = values["sysName"];
    // document.getElementById("logo-ico").setAttribute("href", `${config.baseImageUrl}/${values["sysLogo"]}`)

    return SYS_CONFIG;
}

