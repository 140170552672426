import { Button, Col, Form, Input, Select, Tag, DatePicker,Radio } from "antd";
import React, { useEffect, useState } from "react";
import ContentLayout from "../../layout/ContentLayout"
import DataTablePage from "../public/DataTablePage";
import moment from "moment";
// import RefundOrderDetail from "./RefundOrderDetail";
import FormEditModal from "../public/FormEditModal";

export default () => {

    const [reloadData, setReloadData] = useState(true);
    const [hxOrderNo, setHxOrderNo] = useState(null);

    return (
        <ContentLayout>
            <DataTablePage
                bordered={false}

                apiUrl="hx/records"
                reloadData={reloadData}
                onLoadDataEnd={() => setReloadData(false)}
                queryValueTrans={values => {

                    const [startDate, endDate] = values.times ? values.times : [];
                    return {
                        ...values,
                        startTime: startDate ? startDate.toDate().getTime() : undefined,
                        endTime: endDate ? endDate.toDate().getTime() : undefined,
                        times: undefined
                    }
                }}

                topComponents={<>

                    <Col span={6} >
                        <Form.Item name="recordNo">
                            <Input placeholder="产品订单号" />
                        </Form.Item>

                    </Col>
                    <Col span={6}>
                        <Form.Item name="type">
                            <Select placeholder="类型" allowClear >
                                <Select.Option value={""}>全部</Select.Option>
                                <Select.Option value={"GOODS"}>商品</Select.Option>
                                <Select.Option value={"COUPON"}>卡券</Select.Option>
                                {/* <Select.Option value={"ACTIVITY"}>活动</Select.Option> */}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6} >
                        <Form.Item name="shopUserName">
                            <Input placeholder="核销员" />
                        </Form.Item>
                    </Col>
                    {/* <Col span={12} >
                        <Form.Item name="times">
                            <DatePicker.RangePicker
                                showTime
                                style={{ width: "100%" }}
                                placeholder={["开始时间", "结束时间"]}
                            />
                        </Form.Item>
                    </Col> */}
                    <Col span={6}>
                        <Form.Item>
                            <Button type="primary" htmlType="submit">查询</Button>
                            <Button onClick={()=>setHxOrderNo({
                                type:"GOODS"
                            })} type="default" style={{ marginLeft: 10 }} >核销</Button>
                        </Form.Item>
                    </Col>

                </>}

                columns={[
                    { title: "产品订单号", key: "recordNo",dataIndex: "recordNo" },
                    { title: "产品名称", key: "title", dataIndex: "title" },
                    // { title: "付款", key: "amountReal", dataIndex: "amountReal" },
                    // { title: "优惠", key: "amountFree", dataIndex: "amountFree" },
                    // { title: "退款金额", key: "amountRefund", dataIndex: "amountRefund" },
                    {
                        title: "产品类型", key: "type", render: r => {

                            const payTypeMap = {
                                "GOODS": "商品",
                                "COUPON": "卡券",
                                "ACTIVITY": "活动"
                            };
                            return payTypeMap[`${r.type}`]

                        }
                    },
                    { title: "核销员", key: "shopUserName", dataIndex: "shopUserName" },
                    { title: "核销时间", key: "createTime", render: r => moment(r.createTime).format("YYYY-MM-DD HH:mm") }
                ]}

            />
            {/* <RefundOrderDetail
                payOrderNo={refundOrderNo}
                onClose={()=>setRefundOrderNo(null)}
            /> */}
            <FormEditModal

                apiUrl="hx/record/add"
                modalProps={{
                    title:"核销"
                }}
                formItems={
                    <>
                        <Form.Item label="核销类型" name="type" rules={[{required:true}]}>
                            <Radio.Group>
                                <Radio value="GOODS">商品</Radio>
                                <Radio value="COUPON">卡券</Radio>
                                {/* <Radio value="ACTIVITY">活动</Radio> */}
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item label="订单号" name="orderNo" rules={[{required:true}]}>
                            <Input placeholder="输入/扫码录入"/>
                        </Form.Item>
                    </>
                }
                formData={hxOrderNo}
                onCancel={()=>setHxOrderNo(null)}
                onOk={()=>{

                    setHxOrderNo(null);
                    setReloadData(true)
                }}
            
            />
        </ContentLayout>
    )
}