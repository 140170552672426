import { Select } from "antd";
import React, { useEffect, useState } from 'react';
import { api } from "../../util/axios";


export default (props) => {


    const [data, setData] = useState([]);

    useEffect(() => {

        api("member/recharge/config").get()
            .then(ret => {
                if (ret.code == 200) {

                    setData(ret.data);
                }
            })


    }, []);


    return (
        <Select placeholder={"请选择"} allowClear {...props}>
            {
                data.map(it => {
                    return (
                        <Select.Option value={it.id} key={it.id}>充值:{it.amount} <span style={{}}>(赠送{it.giveAmount})</span></Select.Option>
                    )
                })
            }
        </Select>
    )
}