import { Form, Input, Row, Col, Select, Button, Spin } from "antd";
import React, { useRef, useState } from "react";
import EditModal from "../../components/EditModal";
import { api } from "../../util/axios";

const QueryForm = ({ onSelect }) => {

    const [loading,setLoading] = useState(false);

    const queryCouponCard = (query) => {

        setLoading(true)
        api("api/coupon").get(query).then(ret => {
            if(ret.code == 200){
                onSelect(ret.data);
            }
        })
        .finally(()=>{
            setLoading(false);
        })

    }

    return (
        <Spin spinning={loading}>
            <Form initialValues={{ orderNo: "" }} onFinish={values => {
                // alert(values);
                queryCouponCard(values);
            }}>
                <Form.Item style={{ marginBottom: 10 }} name="orderNo" rules={[{ required: true, message: "卡券编号不能为空" }]}>
                    <Input allowClear autoFocus placeholder="卡券编号/扫码输入" />
                </Form.Item>
                <Form.Item style={{ marginBottom: 0 }} shouldUpdate={true} >
                    {
                        ({ setFieldsValue, getFieldValue, submit }) => {


                            return (
                                <Row style={{ backgroundColor: "#f1f1f1", padding: 1 }}>
                                    {
                                        [1, 2, 3, 4, 5, 6, 7, 8, 9, 0, "清空", "确定"].map((num, index) => {
                                            return (
                                                <Col
                                                    key={index}
                                                    span={8} style={{ padding: 1 }}
                                                    onClick={() => {

                                                        //alert(getFieldValue("orderNo"))

                                                        //删除
                                                        if ("清空" == num) {
                                                            setFieldsValue({ orderNo: "" })
                                                            return;
                                                        }
                                                        if ("确定" == num) {
                                                            submit();
                                                            return;
                                                        }


                                                        setFieldsValue({
                                                            orderNo: `${getFieldValue("orderNo")}${num}`
                                                        });


                                                    }}
                                                >
                                                    <div className="amount-num-item" style={{
                                                        backgroundColor: num == "确定" ? "#E7C77B" : "",
                                                        padding: 10
                                                    }}
                                                    >
                                                        {num}
                                                    </div>
                                                </Col>
                                            )
                                        })
                                    }

                                </Row>
                            )
                        }
                    }

                </Form.Item>

            </Form>
        </Spin>
    )
}

export default (props) => {



    return (
        <EditModal


            {...props}
            title="选择卡券"
            width={300}
            footer={null}
            style={{
                minWidth: 300
            }}
        // bodyStyle={{
        //     padding:10
        // }}
        // headerStyle={{
        //     padding:10
        // }}
        >
            <QueryForm onSelect={card => {
                props.onOk(card);
            }} />
        </EditModal>
    )
}