import { Form, Input, Modal } from "antd";
import React, { useRef, useState } from "react";



export default (props) => {



    const form = useRef(null);

    return (
        <Modal
            title="密码验证"
            {...props}
            centered={true}
            width={350}
            destroyOnClose={true}
            maskClosable={false}
            // footer={null}
            onOk={()=>{
                form.current.validateFields().then(values => {
                    props.onOk(values.password);
                }).catch((e) => {

            
                })
            }}
        >
            <Form ref={ref => form.current = ref}>
                <Form.Item name="password" style={{ marginBottom: 20 }} rules={[{ required: true, message: "请输入密码" }, { len: 6, message: "请输入6位数密码" }]} >
                    <Input placeholder="请输入支付密码" type="password" autoFocus={true} onPressEnter={(e) => {
                        const value = e.target.value;
                        if(value && value.length == 6){
                            props.onOk(e.target.value);
                        }
                      
                    }} />
                </Form.Item>
                <div style={{marginBottom:20,color:"#888"}}>
                    <p>请输入会员卡的支付密码(6位数字)</p>
                </div>
            </Form>
        </Modal>
    )
}
