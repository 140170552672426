import { Select} from "antd";
import React, { useEffect, useState } from 'react';
import { api } from "../../util/axios";
import {
    UserOutlined,
 
} from '@ant-design/icons';

export default (props) => {


    const [data, setData] = useState([]);
    const [loading,setLoading] = useState(false);

    const loadData = (params)=>{
        setLoading(true);
        api("api/member/query").get(params)
        .then(ret => {
            if (ret.code == 200) {

                setData(ret.data);
            }
        }).finally(()=>{
            setLoading(false);
        })
    }

    // useEffect(() =>loadData({query:""}), []);


    console.log(props);

    return (
        <Select 
            placeholder="(会员)手机号码"  
            {...props} 
            allowClear={true}
            showSearch={true}
            loading={loading}
            defaultActiveFirstOption={false}
            showArrow={false}
            filterOption={false}
            allowClear={true}
            onClear={()=>{
                loadData({query:""});
            }}
            onSearch = {(value)=>{
                loadData({
                    query:value
                });
            }}
        >
            {
                data.map(m => {
                    return (
                        <Select.Option key={m.id} value={m.id}>
                            {`(${m.realName}) ${m.phone}`}
                        </Select.Option>
                    )
                })
            }
        </Select>
    )
}