import { Button, Col, Form, Input, Tag } from "antd";
import React, { useState } from "react";
import EditModal from "../../components/EditModal";
import ContentLayout from "../../layout/ContentLayout";
import DataTablePage from "../public/DataTablePage";
import QueryOrderModal from "./query-order";
import RefundOrderModal from "./refund-modal";
import moment from "moment";


export default () => {

    const [queryOrderVisible, setQueryOrderVisable] = useState(false);
    const [order, setOrder] = useState(null);
    const [reloadData,setReloadData] = useState(true)

    return (
        <ContentLayout>


            <DataTablePage

                apiUrl="order/refund/record" 
                reloadData={reloadData}
                onLoadDataEnd={()=>setReloadData(false)}   
                tableProps={{
                    bordered:false
                }}
                topComponents={
                    <>
                        <Col span={8}>
                            <Form.Item name="payOrderNo">
                                <Input placeholder="订单号" />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item  name="shopUserName">
                                <Input placeholder="操作账号" />
                            </Form.Item>
                        </Col>
                     
                        <Col span={6}>
                            <Button type="primary" htmlType="submit">查询</Button>
                            <Button style={{ marginLeft: 15 }} onClick={() => setQueryOrderVisable(true)} >退款</Button>
                        </Col>
                    </>
                }
                columns={[
                    { title: "订单号", key: "orderNo", dataIndex: "payOrderNo" },
                    { title: "退款金额", key: "amount", render:r=>`¥ ${r.amount.toFixed(2)}` },
                    { title: "状态",key:"orderStatus",render:r=>{
                        return r.orderStatus?<Tag color="success">成功</Tag>:<Tag>失败</Tag>

                    } },
                    { title: "操作账号",key:"userName",dataIndex:"userName" },
                    {title:"备注",key:"reason",render:r=><a>{r.reason}</a>},
                    { title: "时间", key: "createTime", render: r => moment(r.createTime).format("YYYY-MM-DD HH:mm") }
             
                ]}


            />

            <QueryOrderModal
                visible={queryOrderVisible}
                onCancel={() => setQueryOrderVisable(false)}
                onOk={order => {
                    setQueryOrderVisable(false);
                    setOrder(order);
                }}
            />

            <RefundOrderModal

                order={order}
                onOk={() => {
                    setOrder(null);
                    setReloadData(true);
                }}
                onCancel={() => setOrder(null)}

            />

        </ContentLayout>
    )
}
