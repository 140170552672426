import React, { useEffect, useState,useImperativeHandle,useRef } from 'react';
import { message, Col, Button, Modal, Form, Input, DatePicker, Spin } from 'antd';
import DataTable from '../../components/DataTable';
import { LoadingOutlined } from '@ant-design/icons';
import { api } from '../../util/axios';


export default ({
    apiUrl,
    topComponents,
    queryParams = {},
    bordered = true,
    pagination = true,
    columns = [],
    tableProps = {},
    reloadData = true,
    onLoadDataEnd,
    queryValueTrans,
    statis = {show:false,component:null,data:{}},
    onValuesChange,
    layoutBodyId="layoutBody",
    tableRef,
    onSubmitQuery,
}) => {


    const [params, setParams] = useState(queryParams);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(pagination ? { count: 0, list: [] } : []);
    const queryForm = useRef(null);

    /**
     * 加载数据
     * @param {*} params 
     */
    const loadData = (params) => {




        console.log(params);

        setParams({ ...params });
        setLoading(true);

        api(apiUrl).get({ ...params }).then(ret => {

            if (ret.code == 200) {
                setData(ret.data);
            }

        }).finally(() => {
            setLoading(false);
        });

        


    }
    useImperativeHandle(tableRef,()=>({
        loadData:(dataParams)=>{
            loadData({
                ...params,
                ...dataParams
            })
        },
        getParams:async ()=>{

             return queryForm.current.validateFields().then(values=>{
                 return queryValueTrans?queryValueTrans(values):values;
             });

        },
        updateData:(fn)=>{
            console.log("data:",data);
            setData(fn(data));
        }
    }));

    useEffect(() => {

        console.log("data=>",reloadData);

        if (reloadData) {
            loadData(params);
            if (onLoadDataEnd) {
                onLoadDataEnd();
            }
        }

    }, [reloadData]);

    return (
        <Spin spinning={loading} indicator={<LoadingOutlined />}>
            <div style={{ width: "100%" }}>
                {
                    topComponents ? (
                        <div style={{ marginBottom: 15, width: "100%" }}>
                            <Form 
                                layout="inline" 
                                onFinish={values => {
                                    const query = queryValueTrans?queryValueTrans(values):values;
                                    loadData({
                                        ...params,
                                        ...query,
                                        pageNo: 1
                                    });
                                    if(onSubmitQuery){
                                        onSubmitQuery(query);
                                    }
                                }}
                                onValuesChange={onValuesChange}
                                ref={ref=>queryForm.current = ref}
                            >
                                {topComponents}
                            </Form>
                        </div>
                    ) : null
                }
                {
                    statis.show?(
                        <div style={{ marginBottom: 15, width: "100%" }}>
                            {
                                statis.component(data.statis?data.statis:statis.data)
                            }
                        </div>
                    ):null
                }
                <DataTable
                    
                    columns={columns}
                    bordered={bordered}
                    pagination={pagination}
                    loading={false}
                    pageNo={params.pageNo}
                    pageSize={params.pageSize}
                    dataTotal={pagination ? data.count : data.length}
                    dataSource={pagination ? data.list : data}
                    onPageChange={(pageNo, pageSize) => {

                        // console.log("pageChange=>",pageNo,pageSize)

                        loadData({
                            ...params,
                            pageNo: pageNo,
                            pageSize: pageSize
                        });
                        const body = document.getElementById(layoutBodyId);
                        if(body){
                            setTimeout(()=>{
                                body.scrollTop = 0;
                            },200)
                           
                        }

                    }}
                    onChange={(pagination, filters, sorter)=>{
                        console.log("onchange=>",pagination, filters, sorter);
                    }}
                    scroll={{
                        scrollToFirstRowOnChange:true,
                        ...tableProps.scroll
                    }}
                    {...tableProps}
                    

                />


            </div>
        </Spin>
    )
}

export const EmptyPage = (props) => {

    return props.children;

}