import { Form, Col, Tabs } from "antd";
import React, { useState } from "react";
import BalanceInvoice from "./balance-invoice";
import BalanceQuery from "./balance-query";
export default ({login}) => {

    const [active, setActive] = useState("recharge");

    return (
        <div style={{ paddingTop: 10 }}>
            <Tabs tabPosition="left" destroyInactiveTabPane={true} activeKey={active} onChange={key => setActive(key)}>
                <Tabs.TabPane tab="余额查询" key="recharge">
                    <BalanceQuery/>
                </Tabs.TabPane>
                <Tabs.TabPane tab="开票记录" key="records" style={{ padding: 10 }}>
                    {/* <Records login={login} /> */}
                    <BalanceInvoice/>
                </Tabs.TabPane>
            </Tabs>
        </div>

    )

    // return (
    //     <ContentLayout>

    //         <DataTablePage

    //             topComponents={<>

    //                 <Col span={8}>
    //                     <Form.Item>
    //                         <MemberQuerySelect />
    //                     </Form.Item>
    //                 </Col>


    //             </>}

    //             columns={[
    //                 {title:"会员",key:"member"},
    //                 {title:"充值金额",key:"amount",dataIndex:"amount"},
    //                 {title:"操作员",key:"shopUserName",dataIndex:"shopUserName"},
    //                 {title:"充值时间",key:"createTime",rend}
    //             ]}

    //         />

    //     </ContentLayout>
    // )
}