
import React, { Component } from 'react';
import { Row, Upload, message, Popover,Modal } from "antd";
import config from '../config';
import { LoadingOutlined, PlusOutlined, DeleteFilled, EyeOutlined } from "@ant-design/icons";



export default class UploadImageList extends Component {


    state = {
        loading: false,
        fileList: []
    }

    componentDidMount() {

        if (this.props.value) {

            const imgs = this.props.value.split(",");

            this.setState({
                fileList: imgs.map((x, index) => {
                    return {
                        url: x,
                        status: 'done',
                        uid: `img-${index}`
                    };
                })
            })

        }

    }

    render() {
        const { loading } = this.state;
        const uploadButton = (
            <div>
                {loading ? <LoadingOutlined /> : <PlusOutlined />}
                <div className="ant-upload-text">上传</div>
            </div>
        );
        return (
            <div style={{ width: "100%" }}>
                <div style={{ display: "flex", width: "100%", flexWrap: "wrap" }}>

                    {



                        this.state.fileList.map((item, i) => {
                            return (
                                <div
                                    key={i}
                                    style={{
                                        width: 104,
                                        height: 104,
                                        border: "1px solid #ddd",
                                        marginRight: 8,
                                        marginBottom: 8,
                                        position: "relative"
                                    }}
                                // onMouseOver={() => {
                                //     this.state.fileList[i].hover = true;
                                //     this.setState({
                                //         fileList: [
                                //             ...this.state.fileList
                                //         ]
                                //     })
                                // }}
                                // onMouseOut={(e) => {
                                //     // console.log(e.target);
                                //     // e.preventDefault();
                                //     this.state.fileList[i].hover = false;
                                //     this.setState({
                                //         fileList: this.state.fileList

                                //     });
                                // }}

                                >




                                    <div
                                        style={{ padding: 5 }}
                                        onDragOver={e => {
                                            e.preventDefault();
                                        }}
                                        index={i}
                                        onDrop={e => {
                                            e.preventDefault();
                                            const index = e.dataTransfer.getData("text");
                                            const nowIndex = e.currentTarget.getAttribute("index");

                                            if (index != nowIndex) {
                                                const temp = this.state.fileList[parseInt(nowIndex)];
                                                this.state.fileList[parseInt(nowIndex)] = this.state.fileList[parseInt(index)];
                                                this.state.fileList[parseInt(index)] = temp;
                                                this.setState({
                                                    fileList: [
                                                        ...this.state.fileList
                                                    ]
                                                });
                                                this.props.onChange(this.state.fileList.map(x => x.url).join(","));
                                            }


                                        }}
                                    >
                                        <Popover content={
                                            <>
                                                <a target="_blank" onClick={()=>{
                                                    Modal.info({
                                                        title:"图片详情",
                                                        width:500,
                                                        content:(
                                                            <div style={{width:400,textAlign:"center"}}>
                                                                <img src={item.url} style={{maxWidth:400}}/>    
                                                            </div>
                                                        )
                                                    })
                                                }}>
                                                    <EyeOutlined />
                                                </a>
                                                <a style={{ marginLeft: 10 }} onClick={() => {
                                                    this.state.fileList.splice(i, 1);
                                                    this.setState({
                                                        fileList: this.state.fileList
                                                    });
                                                    this.props.onChange(this.state.fileList.map(x => x.url).join(","));
                                                }}>
                                                    <DeleteFilled />
                                                    
                                                </a>
                                            </>
                                        }>


                                            <img index={i} src={item.url} style={{
                                                position: "static",
                                                display: "block",
                                                width: 94,
                                                height: 94,
                                                objectFit: "contain"
                                            }}
                                                draggable={true}
                                                onDragStart={e => {
                                                    e.dataTransfer.setData("text", i);
                                                }}

                                              

                                            />
                                        </Popover>
                                    </div>


                                </div>
                            )
                        })

                    }

                    <div>
                        <Upload
                            name="file"
                            accept="image/*"
                            listType="picture-card"
                            className="avatar-uploader"
                            showUploadList={false}
                            // defaultFileList={this.props.value ? this.props.value.split(",").map(url => {
                            //     return { url: url }
                            // }) : []}
                            // fileList={this.state.fileList}
                            action={`${config.baseApiUrl}/upload/image`}
                            withCredentials={true}
                            headers={{
                                "AJDX-SESSION-ID": localStorage.getItem("AJDX-SESSION-ID")
                            }}
                            

                            onRemove={file => {
                                const files = this.state.fileList.filter(x => x.url != file.url);
                                this.setState({
                                    fileList: files
                                });
                                this.props.onChange(files.map(x => x.url).join(","));
                            }}
                            onChange={(info) => {
                                console.log("info=>",info);
                                if(info.file.status == "uploading"){
                                    this.setState({ loading: true });
                                    return;
                                }
                                if (info.file.status == "done") {
                                    this.setState({ loading: false })
                                    const { code, data, msg } = info.file.response;
                                    if (code == 200) {
                                        info.file.url = data;
                                        this.state.fileList.push(info.file);
                                        this.setState({
                                            fileList: [
                                                ...this.state.fileList,
                                            ]
                                        })
                                        const images = this.props.value ? `${this.props.value},${data}` : data;
                                        // console.log(images);
                                        this.props.onChange(images);
                                    } else {
                                        message.error(msg);
                                    }

                                }
                            }}

                        >
                            {uploadButton}
                        </Upload>
                    </div>
                </div>

                <div>
                    <span style={{ font: 12, color: "#ccc" }}>{this.props.tip}</span>
                </div>
            </div>
        )
    }
}