import Modal from "antd/lib/modal/Modal";
import React, { useState } from "react";
import moment from "moment";
import { withConfirm } from "antd/lib/modal/confirm";

const payTypeMap = {
    "MEMBER_CARD": "会员卡",
    "WX_PAY": "微信支付",
    "ALI_PAY": "支付宝"
};

export default ({ order,login,onCancel }) => {

    return (
        <Modal
            visible={order ? true : false}
            title="充值小票打印"
            cancelText="取消"
            okText="确定"
            centered={true}
            onOk={() => {

                // window.print();

                const win = window.document.getElementById("print-iframe").contentWindow;

                win.document.body.innerHTML = window.document.getElementById("print-content").innerHTML;


                win.print();

            //    alert(s);

                onCancel();

            }}
            onCancel={onCancel}
        >
            {
                order ? (
                    <div id="print-content" style={{maxWidth:640}}>
                        {/* <div style={{textAlign:"center",marginBottom:5}}>
                           
                        </div> */}
                        <div style={{ textAlign: "center",marginBottom:10,width:"100%" }}>
                           <div style={{width:"100%",display:"flex",alignItems:"center",justifyItems:"center"}}><img src={login.shopImgUrl} style={{width:30,height:30,borderRadius:15}}/> <span style={{marginLeft:10,fontSize:16,fontWeight:"bold"}}>{login.shopName}</span></div>
                        </div>
                        <div>
                            <h2>会员充值</h2>
                        </div>
                        <div style={{width:"100%",border:0,borderBottom:"1px dashed #000",marginBottom:10}}></div>
                        <div style={{ marginBottom: 3 }}>订单编号:  <span>{order.orderNo}</span></div>
                        <div style={{ marginBottom: 3 }}>充值金额: <span>{order.amount.toFixed(2)}</span></div>
                        <div style={{ marginBottom: 3 }}>赠送金额: <span>{order.giveAmount.toFixed(2)} </span></div>
                        <div style={{ marginBottom: 3 }}>会员账号: <span>{order.memberPhone}</span></div>
                        <div style={{ marginBottom: 3 }}>充值时间: <span>{moment(order.createTime).format("YYYY-MM-DD HH:mm")}</span></div>
                        <div style={{ marginBottom: 10 }}>操作账号: <span>{order.userName}</span></div>
                        <div style={{width:"100%",border:0,borderBottom:"1px dashed #000",marginBottom:10}}></div>
                        <div style={{ marginBottom: 3 }}>打印日期: <span>{moment(new Date()).format("YYYY-MM-DD HH:mm")}</span></div>
                        <div style={{marginBottom:50}}>客户签名：</div>
                    </div>
                ) : null
            }

            <iframe id="print-iframe" style={{maxWidth:640,display:"none"}}></iframe>


        </Modal>
    )


}