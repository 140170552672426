import React, { Component } from 'react';
import { Form, Input, Button, Spin, Layout, Tabs, message } from 'antd';
import { UserOutlined, LockOutlined, SafetyCertificateOutlined, MobileOutlined, LoadingOutlined } from '@ant-design/icons';
import { Redirect } from 'react-router-dom';
import { api } from "../../util/axios"
import './login.css';


const loginApi = api("login");


const FormItem = Form.Item;

class LoginForm extends Component {

    state = {
        loading: false,
        success: false,
        showGoogleCode: false,
        activeKey: "account",
        optSending: false,
        send: false,
        sendTimeCount: 60

    }

    timer = null;

    /**
     * 登录
     */
    handleSubmit = (values) => {
        this.setState({ loading: true });
        loginApi.post({
            ...values,
            // type: this.state.activeKey
        }).then(ret => {
            if (ret.code == 410) {
                this.setState({ showGoogleCode: true })
            }
            if (ret.code == 200) {
                // localStorage.setItem("PAY-SESSION-ID",ret.data);
                this.setState({ success: true });
                // message.success("登录成功")
            }
        }).finally(() => {
            this.setState({ loading: false });
        })
    }

    /**
     * 发送验证吗
     * @param {*} phone 
     */
    sendOpt = (phone) => {

        if (this.state.send) {
            return;
        }

        this.setState({
            optSending: true
        })
        api("login/phone/opt").
            postForm({ phone }).
            then(ret => {

                if (ret.code == 200) {
                    message.success(ret.msg);
                    this.setState({ send: true, sendTimeCount: 60 });
                    let timer = 60;
                    this.timer = setInterval(() => {

                        timer = timer - 1;

                        if (timer <= 0) {
                            this.setState({
                                send: false,
                            });
                            clearInterval(this.timer);
                            return;
                        }
                        this.setState({
                            sendTimeCount: timer
                        });


                    }, 1000);
                }
            }).
            finally(() => {
                this.setState({ optSending: false })
            })
    }

    componentWillUnmount() {

        if (this.timer) {
            clearInterval(this.timer);
        }
    }

    render() {
        if (this.state.success) {
            return <Redirect push to={{
                pathname: "/payment"
            }} />
        }
        return (

            <Form onFinish={this.handleSubmit} className="login-form" ref={ref => this.form = ref}>
                <div style={{ textAlign: "center",marginBottom:30 }}>
                    <h1 style={{ textAlign: "center" }}>收款系统</h1>

                    {/* <img src="./logo.png" style={{width:50}} /> */}
                </div>

                <FormItem name="account" rules={[{ required: true, message: "账号不能为空" }]}>
                    <Input prefix={<UserOutlined />} placeholder={"账号/工号"} allowClear />
                </FormItem>
                <FormItem name="password" rules={[{ required: true, message: "密码不能为空" }]}>
                    <Input type="password" prefix={<LockOutlined />} placeholder={"密码"} allowClear />
                </FormItem>

                <FormItem style={{marginBottom:30}}>

                    <Spin spinning={this.state.loading}>
                        <Button type="primary" htmlType="submit" className="login-form-button">登录</Button>
                    </Spin>
                </FormItem>
                <div style={{textAlign:"center"}}>
                    <span style={{fontSize:12,color:"#666"}}>四川安健得行健康管理股份有限公司</span>
                </div>
            </Form>

        );
    }
}


// const WrappedNormalLoginForm = Form.create()(LoginForm);

/**
 * 登录界面
 */
export default class Login extends Component {




    render() {

        return (
            <Layout style={{ height: "100%", overflow: "hidden" }} >
                <div id="login-page">
                    <LoginForm />
                </div>
            </Layout>

        );
    }
}
