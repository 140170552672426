import { Button, Col, DatePicker, Form, Tag } from "antd";
import React, { useState } from "react";
import DataTablePage from "../public/DataTablePage";
import MemberQuerySelect from "../public/MemberQuerySelect";
import moment from "moment";
import PrintTicketModal from "./PrintTicketModal";


export default ({login}) => {


    const [order,setOrder] = useState(null);

    console.log("login->",login);

    return (
        <>
        <DataTablePage
            apiUrl="member/recharge/records"
            topComponents={<>
                <Col span={8}>
                    <Form.Item name="memberId">
                        <MemberQuerySelect />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item name="times">
                        <DatePicker.RangePicker placeholder={["充值时间","结束结束"]} />
                    </Form.Item>

                </Col>
                <Col span={8}>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">查询</Button>
                    </Form.Item>
                </Col>

            </>}
            tableProps={{
                bordered:false
            }}
            columns={[
                { title: "会员",key:"memberPhone",render:r=>`(${r.memberName})${r.memberPhone}` },
                { title: "订单号", key: "orderNo", dataIndex: "orderNo" },
                { title: "充值金额", key: "amount", dataIndex: "amount" },    
                { title: "赠送金额", key: "giveAmount", dataIndex: "giveAmount" },     
                {title:"状态",key:"status",render:r=><Tag color="green">成功</Tag>},
                {title:"方式",key:"payType",render:r=>{
                    const payTypes = {
                        "WX_PAY":"微信",
                        "ALI_PAY":"支付宝",
                        "OTHER":"其他"
                    }
                    return payTypes[`${r.payType}`]
                }},
                {title:"收银员",key:"shopUserName",dataIndex:"shopUserName"},
                {title:"时间",key:"createTime",render:r=>moment(r.createTime).format("YYYY-MM-DD HH:mm:ss")},
                {title:"打印",key:"print",render:r=><Button size="small" onClick={()=>setOrder({...r})}>打印</Button>}
            ]}

        />
            <PrintTicketModal
                login={login}
                order={order}
                onCancel={()=>{
                    setOrder(null);
                }}
            />
        </>
    )
}