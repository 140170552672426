import React, { Component } from 'react';
import { Modal } from 'antd';

export default class EditModal extends Component {


    render() {
        return (
            <Modal
                bodyStyle={{
                    maxHeight: window.document.body.clientHeight - 150,
                    // maxHeight:640,
                    overflow: "auto"
                }}
             
                centered={true}
                maskClosable={false}
                destroyOnClose={true}
                style={{
                    minWidth: 640
                }}
                {...this.props}
                
                
            >
                {this.props.visible?this.props.children:null}
            </Modal>
        )

    }


}