import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import './App.less';
import routes from './config/routes';
import { ConfigProvider } from 'antd';
import zhCN from 'antd/es/locale/zh_CN';
import moment from 'moment';
import 'moment/locale/zh-cn';
import AuthLoading from './components/AuthLoading';


moment.locale("zh-cn");

// app.run();


export default class App extends Component {


  state = {
    loading: false
  }

  loadConfig = () => {
    //api("config").get().then(ret=>initSysConfig(ret.data)).finally(()=>this.setState({loading:false}))
  }

  componentDidMount() {
    this.loadConfig();

    // window.document.addEventListener("keydown", (e) => {

    //   console.log(e, e.key);

    // })
  }

  render() {

    // console.log(process.env)

    if (this.state.loading) {
      return <AuthLoading />
    }


    return (
      <ConfigProvider locale={zhCN}  >
      
          <HashRouter>
            <Switch>
              {routes.map((route, index) => {
                //console.log("route=>",route)
                return (
                  <Route
                    key={index}
                    path={route.path}
                    render={props => {
                      const Page = route.component;
                      return Page ? (
                        <Page {...props} reloadConfig={this.loadConfig} />
                      ) : null
                    }}
                  // component={}

                  />
                )
              })}
            </Switch>
          </HashRouter>

      </ConfigProvider>
    );
  }

}
