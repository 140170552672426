import { Button, Modal, Result } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { api } from "../../util/axios";


const ShowResult = ({ data,onOk }) => {





    const { orderStatus, message, amount, amountFree, amountReal,orderNo } = data;

    const getStatus = () => {

        if (orderStatus == "SUCCESS") {
            return "success";
        }
        if (orderStatus == "FAIL") {
            return "error"
        }

    }

    useEffect(()=>{

        // if("")

    });

    return (
        <div>
            <Result
                status={getStatus()}
                title={message}
                style={{ marginBottom: 10,padding:10 }}

            />
            <div style={{width:250,margin:"0 auto",marginBottom:25,backgroundColor:"#ddd",borderRadius:5,padding:10}}>
                <div className="res-item">
                    <span className="res-item-left">单号：</span><span className="res-item-right">{orderNo}</span>
                </div>
                <div className="res-item">
                    <span className="res-item-left">应收：</span><span className="res-item-right">{amount.toFixed(2)}</span>
                </div>
                <div  className="res-item">
                    <span className="res-item-left">优惠：</span><span className="res-item-right">{amountFree.toFixed(2)}</span>
                </div>
                <div  className="res-item">
                    <span className="res-item-left">实收：</span><span className="res-item-right">{amountReal.toFixed(2)}</span>
                </div>
            </div>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}>
                {/* <Button type="primary">确定</Button> */}
                <Button size="large" type="primary" style={{width:280}} autoFocus={true} onClick={()=>{
                    onOk();
                }} >确定</Button>
            </div>
        </div>
    )
}

export default ({ data,onOk }) => {

    return (
        <Modal
            visible={data ? true : false}
            centered={true}
            destroyOnClose={false}
            maskClosable={false}
            closable={false}
            width={350}
            footer={null}
            onOk={onOk}
        >


            {
                data ? (
                    <ShowResult data={data} onOk={()=>onOk()} />
                ) : null
            }

        </Modal>
    )
}