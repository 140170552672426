// import asyncComponent from "../components/AsyncComponent";
import Login from "../pages/login";
import BaLayout from "../layout/BaLayout";
export default [
    {
        path: "/login",
        title: "登录",
        name:"login",
        component: Login
    },
    {
        path: "/",
        name:"admin",
        title:"管理后台",
        component:BaLayout
        
    }



]